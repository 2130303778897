import { Component, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChildActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    template: `
        <sbpro-toast-view class="ngb-toasts" aria-live="polite" aria-atomic="true">
        </sbpro-toast-view
        ><router-outlet></router-outlet>
    `,
})
export class AppComponent {
    company = '';
    constructor(public router: Router, private titleService: Title, private renderer: Renderer2) {
        this.router.events
            .pipe(filter((event) => event instanceof ChildActivationEnd))
            .subscribe((event) => {
                let snapshot = (event as ChildActivationEnd).snapshot;

                while (snapshot.firstChild !== null) {
                    snapshot = snapshot.firstChild;
                }
                this.titleService.setTitle(snapshot.data.title || 'Smart-View Technology');
            });
    }

    ngOnInit() {
        this.company = localStorage.getItem('company')!;
        console.log(this.company)
        // Change the favicon when the component is initialized based on the condition
        this.updateTitleAndFavicon(this.company!);
        if (this.company !== "landis") {
            // First script
            const script1 = document.createElement("script");
            script1.type = "text/javascript";
            script1.async = true;
            script1.defer = true;
            script1.src = "//js-eu1.hs-scripts.com/40115116.js";
            document.body.appendChild(script1);
          
            // Second script with id check to avoid duplicates
            if (!document.getElementById("hs-script-loader")) {
              const script2 = document.createElement("script");
              script2.type = "text/javascript";
              script2.id = "hs-script-loader";
              script2.async = true;
              script2.defer = true;
              script2.src = "//js-eu1.hs-scripts.com/40115116.js";
              document.body.appendChild(script2);
            }
          }
    }
    updateTitleAndFavicon(condition: string) {
        // Set title based on condition
        const title = condition == 'landis'
            ? 'Utilities Management Software | Landis Gyr | Smart-View Technology'
            : 'Utility Management Software | Smart-View Technology'; // Change this to whatever you want

        this.titleService.setTitle(title);

        // Update favicon based on condition
        const faviconUrl = condition == 'landis' ? 'assets/landis-assets/images/favicon.png' : 'favicon.png';
        this.updateFavicon(faviconUrl);
    }

    updateFavicon(faviconUrl: string) {
        let existingLink = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (existingLink && existingLink.parentNode) {
            existingLink.parentNode.removeChild(existingLink);
        }

        const link: HTMLLinkElement = this.renderer.createElement('link');
        link.setAttribute('rel', 'icon');
        link.setAttribute('href', faviconUrl);
        const head = this.renderer.selectRootElement('head', true);
        this.renderer.appendChild(head, link);
    }
}
