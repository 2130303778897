import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
// import { ModalsVerticallyCenteredComponent } from '../../../metering/containers/modals-vertically-centered/modals-vertically-centered.component';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import $ from 'jquery'
import { ApiRequestService } from '@common/services/api-request.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { ToastService } from '@common/services/toast.service';


@Component({
    selector: 'sbpro-dashboard-home',
    templateUrl: './dashboard-home.component.html',
    styleUrls: ['dashboard-home.component.scss'],
})
export class DashboardHomeComponent implements OnInit {
    rightNow!: Date;
    list = [1, 1, 1, 1, 1]
    activeLevel = "";

    deviceList: any = [];
    deviceName = "";
    deviceID = "";
    deviceVersion = "";
    hasPhasor = "";
    hasHourly = "no";
    deviceType = "";
    jsonUrl = "";
    wordRegex = /(?=[A-Z_])/;
    wordRegexReplace = /_/g
    meterType = "water";
    unit = "L";
    unit_2 = "kl";
    count = 1;
    deviceManufacturer = "";

    deviceData: any = [{ meterType: "water" }];
    meteringFarmID = "";
    meteringPointsArr: any = [];
    hasTOU = "no";
    meteringFarms: any = [];
    meteringPoints: any = [];
    meteringSections: any = [];

    insights: any = [];

    isLoggedin?: boolean;
    defaultOptions: any = {};
    pmLevel = 4;
    meteringSectionManagement = "";
    deviceManagement = "";
    meteringPointManagement = "";
    companyCheck = localStorage.getItem('company')
    isLoaded = false;

    @ViewChild('confirmationModal') confirmationModal!: TemplateRef<unknown>;


    constructor(
        private modalService: NgbModal,
        private ngxService: NgxUiLoaderService,
        private http: HttpClient,
        private toastService: ToastService,
        private router: Router,
        private apiRequest: ApiRequestService,
        private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.isLoaded = true;

        localStorage.setItem("showReporting", "yes");

        if (!localStorage.getItem("hasMeteringSection")) localStorage.setItem("hasMeteringSection", "no");
        if (!localStorage.getItem("hasMeteringPoint")) localStorage.setItem("hasMeteringPoint", "no");
        this.pmLevel = parseInt(localStorage.getItem("pmLevel") as string);

        let permissions = JSON.parse(localStorage.getItem("permissions") as string);
        this.deviceManagement = permissions.deviceManagement;
        this.meteringSectionManagement = permissions.meteringSectionManagement;
        this.meteringPointManagement = permissions.meteringPointManagement;

        this.defaultOptions = {
            autohide: true,
            delay: 5000,
        };
        localStorage.setItem("active", "dashboard");

        if (localStorage.getItem("licenseExpiry") && localStorage.getItem("licenseExpiry") == "yes") {
            this.router.navigate(['/account/account-payment']);
        }

        this.ngxService.start();
        setTimeout(() => {
            this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
            $("#credential_picker_container").css("display", "none")
        }, 10000);

        if (!localStorage.getItem("userID")) {
            this.router.navigateByUrl("/auth/login");
        }

        this.rightNow = new Date();

    }

    ngAfterViewInit() {
        this.activeLevel = localStorage.getItem("activeLevel") as string;
        this.deviceID = localStorage.getItem("deviceID") as string;
        this.deviceVersion = localStorage.getItem("deviceVersion") as string;
        this.unit = localStorage.getItem("unit") as string;
        this.meterType = localStorage.getItem("meterType") as string;
        this.changeDetectorRef.detectChanges();
        this.jsonUrl = "/" + localStorage.getItem("meterType") + "/" + localStorage.getItem("deviceType") + ".json";
        localStorage.setItem("active", "dashboard");

        localStorage.setItem("hasTOU", "no");
        localStorage.setItem("hasRate", "no");
        localStorage.setItem("hasCredit", "no");
        localStorage.setItem("hasPhasorDiagram", "no");
        localStorage.setItem("deviceVersion", "1");


        if (!localStorage.getItem("metering_hierarchy")) {
            this.getMeteringHierachyReload();
        }

        this.changeDetectorRef.detectChanges();

        if (localStorage.getItem("activeLevel") == 'meteringFarm') {
            this.getMeteringHierachy();
        }
        else if (localStorage.getItem("activeLevel") == 'meteringSection') {
            this.getMeteringHierachy();
        }
        else if (localStorage.getItem("activeLevel") == 'device') {

            if (localStorage.getItem("deviceID") && localStorage.getItem("meterType") && this.deviceID != "") {
                this.getSummaryReadings();
            }
            else if (this.deviceManagement != "no-access") {
                this.getMeteringHierachy();
            }
            else {
                const defaultOptions = {
                    autohide: true,
                    delay: 5000,
                };
                this.toastService.show("No Dashboard Access", "You do not have any devices \n ", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                this.modalService.open(this.confirmationModal).result.then(
                    (result) => {
                        if (result === 'DASHBOARD') {
                        }
                    },
                    (reason) => { }
                );
                const bulkIds = localStorage.getItem("bulkIds");
                const selectedMeter = localStorage.getItem("selectedMeter");
                const meterSectionIdSync = localStorage.getItem("meterSectionIdSync");

                localStorage.clear();
                if (meterSectionIdSync) {
                    localStorage.setItem("meterSectionIdSync", meterSectionIdSync);
                }

                if (bulkIds) {
                    localStorage.setItem("bulkIds", bulkIds);
                    localStorage.setItem("selectedMeter", selectedMeter ?? '');
                }
                this.router.navigateByUrl("/auth/login");
            }
        }
        else {
            if (localStorage.getItem("activeLevel") != "credit" && localStorage.getItem("activeLevel") != "statement" && localStorage.getItem("activeLevel") != "modems" && localStorage.getItem("deviceID") && localStorage.getItem("deviceID") != "") {
                this.getSummaryReadings();
            }
            if (localStorage.getItem("activeLevel") != "credit" && localStorage.getItem("activeLevel") != "statement" && localStorage.getItem("activeLevel") != "modems") {
                this.getMeteringHierachy();
            }
            else if (localStorage.getItem("activeLevel") != "credit" && localStorage.getItem("activeLevel") != "statement" && (localStorage.getItem("pmLevel") == "3" || localStorage.getItem("pmLevel") == "4")) {
                this.getMeteringHierachy();
            }

        }

        if (localStorage.getItem("reload") && localStorage.getItem("reload") == "yes") {
            this.getMeteringHierachyReload();
        }


        if (this.meterType == "water") {
            this.unit_2 = "kl";
        }
        else if (this.meterType == "temp") {
            this.unit_2 = "°C";
        }
        else {
            this.unit_2 = this.unit;
        }

        this.changeDetectorRef.detectChanges();

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.deviceData?.firstChange) {
            return;
        }
        this.changeDetectorRef.detectChanges();
    }



    getMeteringHierachy() {
        let body = {
            payload: {
                userID: parseInt(localStorage.getItem("userID") as string),
            },
        };

        let jsonF = "metering_hierarchy.json";

        if (localStorage.getItem("pmLevel") == "2") {
            jsonF = "metering_hierarchy_2.json";
        }
        else if (localStorage.getItem("pmLevel") == "3" || localStorage.getItem("pmLevel") == "4") {
            jsonF = "metering_hierarchy_3.json";
        }

        const defaultOptions = {
            autohide: true,
            delay: 10000,
        };

        var res: any = JSON.parse(localStorage.getItem('metering_hierarchy') as string);

        // this.apiRequest.apiRequest("metering/metering_hierarchy/", jsonF, body)
        //     .subscribe((res) => {
        if (res && res.success) {
            if (res.data.meteringFarms) {
                this.meteringFarms = res.data.meteringFarms;
                localStorage.setItem("meteringFarm", res.data.meteringFarms[0].meteringFarmName);

                for (var i = 0; i < res.data.meteringFarms.length; i++) {
                    if (res.data.meteringFarms[i].meteringSections.length > 0) {
                        this.meteringSections.push(res.data.meteringFarms[i].meteringSections);
                    }
                    for (var j = 0; j < res.data.meteringFarms[i].meteringSections.length; j++) {
                        if (res.data.meteringFarms[i].meteringSections[j].meteringPoints.length > 0) {
                            this.meteringPointsArr[
                                res.data.meteringFarms[i].meteringSections[j].meteringSectionID
                            ] = res.data.meteringFarms[i].meteringSections[j].meteringPoints;

                            for (var x = 0; x < res.data.meteringFarms[i].meteringSections[j].meteringPoints.length; x++) {
                                if (res.data.meteringFarms[i].meteringSections[j].meteringPoints[x].meters && res.data.meteringFarms[i].meteringSections[j].meteringPoints[x].meters.length > 0) {
                                    this.deviceList.push(res.data.meteringFarms[i].meteringSections[j].meteringPoints[x].meters)
                                }
                            }
                        }
                    }
                }
            }
            else if (res.data.meteringSections) {
                this.meteringSections = res.data.meteringSections;
                //demo test

                for (var j = 0; j < res.data.meteringSections.length; j++) {
                    if (res.data.meteringSections[j].meteringPoints.length > 0) {
                        this.meteringPointsArr[
                            res.data.meteringSections[j].meteringSectionID
                        ] = res.data.meteringSections[j].meteringPoints;

                        for (var x = 0; x < res.data.meteringSections[j].meteringPoints.length; x++) {
                            if (res.data.meteringSections[j].meteringPoints[x].meters.length > 0) {
                                this.deviceList.push(res.data.meteringSections[j].meteringPoints[x].meters)
                            }
                        }
                    }
                }
            }
            else if (res.data.meteringPoints) {
                this.meteringPoints = res.data.meteringPoints;
                this.meteringPointsArr[0] = res.data.meteringPoints;
                for (var x = 0; x < res.data.meteringPoints.length; x++) {
                    if (res.data.meteringPoints[x].meters.length > 0) {
                        this.deviceList.push(res.data.meteringPoints[x].meters)
                    }
                }
            }


            if (this.meteringSections.length > 0) {
                localStorage.setItem("hasMeteringSection", "yes");
            }
            else {
                localStorage.setItem("hasMeteringSection", "no");
            }
            if (this.meteringPointsArr.length > 0) {
                localStorage.setItem("hasMeteringPoint", "yes");
            }
            else {
                localStorage.setItem("hasMeteringPoint", "no");
            }


            if (this.deviceManagement == "no-access") {
                this.toastService.show("No Dashboard Access", "You do not have any devices \n ", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                this.modalService.open(this.confirmationModal).result.then(
                    (result) => {
                        if (result === 'DASHBOARD') {
                        }
                    },
                    (reason) => { }
                );
                const bulkIds = localStorage.getItem("bulkIds");
                const selectedMeter = localStorage.getItem("selectedMeter");
                const meterSectionIdSync = localStorage.getItem("meterSectionIdSync");

                localStorage.clear();
                if (meterSectionIdSync) {
                    localStorage.setItem("meterSectionIdSync", meterSectionIdSync);
                }

                if (bulkIds) {
                    localStorage.setItem("selectedMeter", selectedMeter ?? '');
                    localStorage.setItem("bulkIds", bulkIds);

                }
            }
            else if (localStorage.getItem("pmLevel") == "1") {
                if (this.meteringSections.length < 1) {
                    this.toastService.show("No Metering Section Found", "You do not have any Metering Sections \n Please add a Metering Section first", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    this.router.navigateByUrl("/dashboard/metering/meteringSection");
                }
                else if (this.meteringPointsArr.length < 1) {
                    this.toastService.show("No Metering Points Found", "You do not have any Metering Points \n Please add a Metering Point first", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    this.router.navigateByUrl("/dashboard/metering/meteringPoint");
                }
                else if (this.deviceList.length < 1) {
                    this.toastService.show("No Device Found", "You do not have any Devices \n Please add a Device first", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    this.router.navigateByUrl("/dashboard/metering/deviceSelect");
                }
            }
            else if (localStorage.getItem("pmLevel") == "2") {
                if (this.meteringSections.length < 1) {
                    this.toastService.show("No Metering Section Found", "You do not have any Metering Sections \n ", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    const bulkIds = localStorage.getItem("bulkIds");
                    const selectedMeter = localStorage.getItem("selectedMeter");
                    const meterSectionIdSync = localStorage.getItem("meterSectionIdSync");

                    localStorage.clear();
                    if (meterSectionIdSync) {
                        localStorage.setItem("meterSectionIdSync", meterSectionIdSync);
                    }

                    if (bulkIds) {
                        localStorage.setItem("selectedMeter", selectedMeter ?? '');
                        localStorage.setItem("bulkIds", bulkIds);

                    }
                    this.router.navigateByUrl("/auth/login");
                }
                else if (this.meteringPointsArr.length < 1) {
                    this.toastService.show("No Metering Points Found", "You do not have any Metering Points \n ", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    this.router.navigateByUrl("/dashboard/metering/meteringPoint");
                }
                else if (this.deviceList.length < 1) {
                    this.toastService.show("No Device Found", "You do not have any Devices \n Please add a Device first", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    this.router.navigateByUrl("/dashboard/metering/deviceSelect");
                }

            }
            else if (localStorage.getItem("pmLevel") == "3") {
                if (this.meteringPointsArr.length < 1) {
                    this.toastService.show("No Metering Points Found", "You do not have any Metering Points \n ", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    const bulkIds = localStorage.getItem("bulkIds");
                    const selectedMeter = localStorage.getItem("selectedMeter");
                    const meterSectionIdSync = localStorage.getItem("meterSectionIdSync");

                    localStorage.clear();
                    if (meterSectionIdSync) {
                        localStorage.setItem("meterSectionIdSync", meterSectionIdSync);
                    }

                    if (bulkIds) {
                        localStorage.setItem("selectedMeter", selectedMeter ?? '');
                        localStorage.setItem("bulkIds", bulkIds);

                    }
                    this.router.navigateByUrl("/auth/login");
                }
                else if (this.deviceList.length < 1) {
                    this.toastService.show("No Device Found", "You do not have any Devices \n Please add a Device first", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    this.router.navigateByUrl("/dashboard/metering/deviceSelect");
                }

            }
            else if (localStorage.getItem("pmLevel") == "4") {
                if (this.meteringPointsArr.length < 1) {
                    this.toastService.show("No Metering Points Found", "You do not have any Metering Points \n ", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    const bulkIds = localStorage.getItem("bulkIds");
                    const selectedMeter = localStorage.getItem("selectedMeter");
                    const meterSectionIdSync = localStorage.getItem("meterSectionIdSync");

                    localStorage.clear();
                    if (meterSectionIdSync) {
                        localStorage.setItem("meterSectionIdSync", meterSectionIdSync);
                    }

                    if (bulkIds) {
                        localStorage.setItem("selectedMeter", selectedMeter ?? '');
                        localStorage.setItem("bulkIds", bulkIds);

                    }
                    this.router.navigateByUrl("/auth/login");
                }
                else if (this.deviceList.length < 1) {
                    this.toastService.show("No Device Found", "You do not have any Devices \n ", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    const bulkIds = localStorage.getItem("bulkIds");
                    const selectedMeter = localStorage.getItem("selectedMeter");
                    const meterSectionIdSync = localStorage.getItem("meterSectionIdSync");

                    localStorage.clear();
                    if (meterSectionIdSync) {
                        localStorage.setItem("meterSectionIdSync", meterSectionIdSync);
                    }

                    if (bulkIds) {
                        localStorage.setItem("selectedMeter", selectedMeter ?? '');
                        localStorage.setItem("bulkIds", bulkIds);

                    }
                    this.router.navigateByUrl("/auth/login");
                }

            }

            if (this.deviceList.length > 0) {
                let meters = this.deviceList[0];
                for (var i = 0; i < meters.length; i++) {

                    if (localStorage.getItem("deviceID") == meters[i].deviceID) {

                        this.deviceID = meters[i].deviceID;
                        this.deviceName = meters[i].deviceName;
                        this.deviceVersion = meters[i].version;
                        this.deviceType = meters[i].deviceType;

                        localStorage.setItem("deviceID", this.deviceID);
                        localStorage.setItem("deviceName", this.deviceName);
                        localStorage.setItem("deviceVersion", this.deviceVersion);
                        localStorage.setItem("meterType", meters[i].meterType);
                        localStorage.setItem("deviceType", meters[i].deviceType);

                        this.jsonUrl = "/" + meters[i].meterType + "/" + meters[i].deviceType + ".json";
                        this.jsonUrl = this.jsonUrl.toLocaleLowerCase();


                        localStorage.setItem("jsonFile", this.jsonUrl);

                    }
                    else if (!localStorage.getItem("deviceID")) {

                        this.deviceID = meters[0].deviceID;
                        this.deviceName = meters[0].deviceName;
                        this.deviceVersion = meters[0].version;
                        this.deviceType = meters[0].deviceType;

                        localStorage.setItem("deviceID", this.deviceID);
                        localStorage.setItem("deviceName", this.deviceName);
                        localStorage.setItem("deviceVersion", this.deviceVersion);

                        this.jsonUrl = "/" + meters[0].meterType + "/" + meters[0].deviceType + ".json";
                        this.jsonUrl = this.jsonUrl.toLocaleLowerCase();
                        localStorage.setItem("jsonFile", this.jsonUrl);
                        localStorage.setItem("meterType", meters[0].meterType);
                        localStorage.setItem("deviceType", meters[0].deviceType);

                        this.meterType = meters[0].meterType;
                        this.changeDetectorRef.detectChanges();
                    }

                    localStorage.setItem("deviceVersion", this.deviceVersion);
                }
                this.getSummaryReadings();
            }

        }
        else {
            this.ngxService.start();

            setTimeout(() => {
                this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
            }, 2000);
            this.router.navigateByUrl("/dashboard/metering/deviceSelect");
        }
        this.changeDetectorRef.detectChanges();

    }


    getMeteringHierachyReload() {
        let body = {
            payload: {
                userID: parseInt(localStorage.getItem("userID") as string),
            },
        };
        localStorage.setItem("active", "dashboard");

        let permissions = JSON.parse(localStorage.getItem("permissions") as string);
        this.deviceManagement = permissions.deviceManagement;
        this.meteringSectionManagement = permissions.meteringSectionManagement;
        this.meteringPointManagement = permissions.meteringPointManagement;

        let jsonF = "metering_hierarchy.json";
        if (localStorage.getItem("pmLevel") == "1") {
            jsonF = "metering_hierarchy.json";
        }
        if (localStorage.getItem("pmLevel") == "2") {
            jsonF = "metering_hierarchy_2.json";
        }
        else if (localStorage.getItem("pmLevel") == "3" || localStorage.getItem("pmLevel") == "4") {
            jsonF = "metering_hierarchy_3.json";
        }

        const defaultOptions = {
            autohide: true,
            delay: 10000,
        };

        var res: any = JSON.parse(localStorage.getItem('metering_hierarchy') as string);
        // this.apiRequest.apiRequest("metering/account_hierarchy/", jsonF, body)
        //     .subscribe((res) => {
        if (res) {
            console.log(res.message);
            if (res.success) {

                if (res.data.meteringFarms) {
                    this.meteringFarms = res.data.meteringFarms;
                    localStorage.setItem("meteringFarm", res.data.meteringFarms[0].meteringFarmName);

                    for (var i = 0; i < res.data.meteringFarms.length; i++) {
                        if (res.data.meteringFarms[i].meteringSections.length > 0) {
                            this.meteringSections.push(res.data.meteringFarms[i].meteringSections);
                        }
                        for (var j = 0; j < res.data.meteringFarms[i].meteringSections.length; j++) {
                            if (res.data.meteringFarms[i].meteringSections[j].meteringPoints.length > 0) {
                                this.meteringPointsArr[
                                    res.data.meteringFarms[i].meteringSections[j].meteringSectionID
                                ] = res.data.meteringFarms[i].meteringSections[j].meteringPoints;

                                for (var x = 0; x < res.data.meteringFarms[i].meteringSections[j].meteringPoints.length; x++) {
                                    if (res.data.meteringFarms[i].meteringSections[j].meteringPoints[x].meters.length > 0) {
                                        this.deviceList.push(res.data.meteringFarms[i].meteringSections[j].meteringPoints[x].meters)
                                    }
                                }
                            }
                        }
                    }
                }
                else if (res.data.meteringSections) {
                    this.meteringSections = res.data.meteringSections;
                    //demo test

                    for (var j = 0; j < res.data.meteringSections.length; j++) {
                        if (res.data.meteringSections[j].meteringPoints.length > 0) {
                            this.meteringPointsArr[
                                res.data.meteringSections[j].meteringSectionID
                            ] = res.data.meteringSections[j].meteringPoints;

                            for (var x = 0; x < res.data.meteringSections[j].meteringPoints.length; x++) {
                                if (res.data.meteringSections[j].meteringPoints[x].meters.length > 0) {
                                    this.deviceList.push(res.data.meteringSections[j].meteringPoints[x].meters)
                                }
                            }
                        }
                    }
                }
                else if (res.data.meteringPoints) {
                    this.meteringPoints = res.data.meteringPoints;
                    this.meteringPointsArr[0] = res.data.meteringPoints;
                    for (var x = 0; x < res.data.meteringPoints.length; x++) {
                        if (res.data.meteringPoints[x].meters.length > 0) {
                            this.deviceList.push(res.data.meteringPoints[x].meters)
                        }
                    }
                }


                if (this.meteringSections.length > 0) {
                    localStorage.setItem("hasMeteringSection", "yes");
                }
                else {
                    localStorage.setItem("hasMeteringSection", "no");
                }
                if (this.meteringPointsArr.length > 0) {
                    localStorage.setItem("hasMeteringPoint", "yes");
                }
                else {
                    localStorage.setItem("hasMeteringPoint", "no");
                }

            }

        }
    }


    getSummaryReadings() {

        setTimeout(() => {
            this.isLoaded = false;
        }, 500)
        localStorage.setItem("summaryReadings", "running");

        let body = {
            "payload": {
                "deviceID": localStorage.getItem("deviceID"),
                "platform": "web"
            }
        }
        this.jsonUrl = "/" + localStorage.getItem("meterType") + "/" + localStorage.getItem("deviceType") + ".json";
        this.jsonUrl = this.jsonUrl.toLocaleLowerCase();
        localStorage.setItem("jsonFile", this.jsonUrl);
        this.deviceVersion = "";
        this.changeDetectorRef.detectChanges();

        this.apiRequest.apiRequest("utilities/summary_readings/", localStorage.getItem("meterType") + "/summary_readings.json", body)
            .subscribe(res => {
                if (res.data.consumptions) {
                    this.deviceData = res.data;
                    if (res.data.hasTOU) {
                        this.hasTOU = res.data.hasTOU;
                        localStorage.setItem("hasTOU", res.data.hasTOU);
                    }
                    this.activeLevel = "device";

                    if (res.data.hasRate) localStorage.setItem("hasRate", res.data.hasRate);
                    if (res.data.hasCredits) localStorage.setItem("hasCredit", res.data.hasCredits);

                    this.deviceName = res.data.deviceName;

                    this.hasHourly = res.data.hasHourly;
                    let deviceType = res.data.deviceType;
                    this.deviceType = res.data.deviceType;

                    this.meterType = res.data.meterType;
                    this.deviceVersion = res.data.deviceVersion;
                    this.hasPhasor = res.data.hasPhasorDiagram
                    localStorage.setItem("deviceModel", res.data.deviceModel);
                    if (res.data.hasPhasorDiagram) localStorage.setItem("hasPhasorDiagram", res.data.hasPhasorDiagram);

                    localStorage.setItem("lastReadings", JSON.stringify(res.data.lastReadings));
                    localStorage.setItem("deviceManagementID", res.data.deviceManagementID);
                    localStorage.setItem("deviceManufacturer", res.data.deviceManufacturer);
                    this.deviceManufacturer = res.data.deviceManufacturer;
                    this.changeDetectorRef.detectChanges();

                    if (this.hasHourly == "yes") {
                        $(".timeline").addClass("hasHourly");
                    }
                    if (this.hasHourly == "no") {
                        $(".timeline").addClass("hasNoHourly");
                    }

                    this.unit = res.data.unit;
                    if (this.meterType == "water") {
                        this.unit_2 = "kl";
                        localStorage.setItem("unit_2", "kl");
                    }
                    if (this.meterType == "temp") {
                        this.unit_2 = "°C";
                        res.data.unit = "°C";
                    }

                    localStorage.setItem("unit", res.data.unit);
                    localStorage.setItem("deviceID", this.deviceID);
                    localStorage.setItem("meterType", this.meterType);
                    localStorage.setItem("deviceName", this.deviceName);
                    localStorage.setItem("deviceType", deviceType.toLowerCase());
                    localStorage.setItem("deviceVersion", res.data.deviceVersion);
                    localStorage.setItem("activeLevel", "device");
                    localStorage.setItem("billingDate", res.data.billingDate);
                    if (res.data.hasConfig) localStorage.setItem("hasConfig", res.data.hasConfig);

                    localStorage.setItem("deviceManagementID", res.data.deviceManagementID);
                    this.changeDetectorRef.detectChanges();
                    this.getDeviceInsights();
                    localStorage.setItem("summaryReadings", "complete");

                    setTimeout(() => {
                        this.ngxService.stop();
                    }, 5000);

                    setTimeout(() => {
                        this.getDeviceData();
                        this.isLoaded = true;
                    }, 2000);
                    this.changeDetectorRef.detectChanges();

                }
                this.changeDetectorRef.detectChanges();

            },
                (error: any) => {
                    const defaultOptions = {
                        autohide: true,
                        delay: 10000,
                    };
                    localStorage.removeItem("deviceID");
                    console.log(error);
                    let message = "";
                    for (var i = 0; i < error.error.data.length; i++) {
                        message += error.error.data[i] + "\n";
                    }
                    this.toastService.show("No Utilities Device", "The current device linked to your Account cannot be loaded. \n.", { headerClasses: 'bg-danger text-light', ...defaultOptions })
                    if (error.error.message == "authentication error") {
                        const bulkIds = localStorage.getItem("bulkIds");
                        const selectedMeter = localStorage.getItem("selectedMeter");
                        const meterSectionIdSync = localStorage.getItem("meterSectionIdSync");

                        localStorage.clear();
                        if (meterSectionIdSync) {
                            localStorage.setItem("meterSectionIdSync", meterSectionIdSync);
                        }

                        if (bulkIds) {
                            localStorage.setItem("selectedMeter", selectedMeter ?? '');
                            localStorage.setItem("bulkIds", bulkIds);
                        }
                        window.location.assign("/auth/login");
                    }
                    else {
                        window.location.assign("/auth/login");
                    }
                });
    }


    getDeviceData() {

        let body = {
            "payload": {
                "deviceID": localStorage.getItem("deviceID"),
                "platform": "web"
            }
        }
        this.jsonUrl = localStorage.getItem("jsonFile") as string;

        this.apiRequest.apiRequest("utilities/device_data/", localStorage.getItem("meterType") + "/device_data.json", body)
            .subscribe(res => {
                if (res.data.deviceData) {
                    this.deviceData.meterData = res.data.deviceData;
                    this.changeDetectorRef.detectChanges();

                }
                this.changeDetectorRef.detectChanges();

            });
    }


    getDeviceInsights() {

        let body = {
            "payload": {
                "deviceID": localStorage.getItem("deviceID"),
                "platform": "web"
            }
        }
        this.jsonUrl = localStorage.getItem("jsonFile") as string;

        this.apiRequest.apiRequest("utilities/device_insights/", localStorage.getItem("meterType") + "/device_insights.json", body)
            .subscribe(res => {
                if (res.data.insights && res.data.insights.length > 0) {
                    this.insights = res.data.insights;
                    this.changeDetectorRef.detectChanges();
                }
                else {
                    this.insights = [{ "title": localStorage.getItem("deviceID"), "info": "No Insights" }]
                }
                this.changeDetectorRef.detectChanges();

            });
    }

}
