import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  OnDestroy,
  HostBinding,
  SimpleChanges,
  ViewChild,
  TemplateRef,
  PipeTransform,
} from "@angular/core";

import { UtilityService } from "@common/services";
import {
  sideNavItemsList,
  sideNavSections,
} from "@modules/navigation/data/side-nav-dashboard.data";
import {
  keyvalue,
  SideNavItem,
  SideNavItems,
  SideNavSection,
} from "@modules/navigation/models";
import { NavigationService } from "@modules/navigation/services";
import { BehaviorSubject, Subscription, timeout } from "rxjs";
import { ApiRequestService } from "@common/services/api-request.service";
import { ToastService } from "@common/services/toast.service";
import { Router } from "@angular/router";
import { Buffer } from "buffer";

@Component({
  selector: "sbpro-layout-dashboard",
  templateUrl: "./layout-dashboard.component.html",
  styleUrls: ["layout-dashboard.component.scss"],
})
export class LayoutDashboardComponent implements OnInit, OnDestroy {
  @Input() static = false;
  @Input() light = false;
  @Input() rtl = false;
  @Input() activeTab!: string;

  @HostBinding("class.sidenav-toggled") sideNavHidden = false;
  subscription: Subscription = new Subscription();
  sideNavItemsList = sideNavItemsList;
  sideNavSections = sideNavSections;
  sidenavStyle = "sidenav-dark";
  _isDataLoaded: boolean = false;
  companyCheck = localStorage.getItem("company");

  dashboard = "";
  reporting = "";
  users = "";
  notifications = "";
  tariff = "";
  alert = "";
  integration = "";
  jobbox = "";
  diagnostics = "";
  geolocation = "";

  reloadNav = "no";
  impersination = "no";

  deviceManagement = "";
  meteringSectionManagement = "";
  meteringPointManagement = "";
  tariffManagement = "";
  notificationManagement = "";
  reportManagement = "";
  userManagement = "";
  pmLevel = "";

  constructor(
    private apiRequest: ApiRequestService,
    public utilityService: UtilityService,
    private toastService: ToastService,
    private router: Router,
    public navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  ngOnInit() {
    if (!localStorage.getItem("userID")) {
      this.router.navigateByUrl("/auth/login");
    }

    let dataitem: any = [];
    this.reloadNav = "yes";
    sideNavItemsList.AddData(dataitem);
    this.changeDetectorRef.detectChanges();

    this.pmLevel = localStorage.getItem("pmLevel") as string;

    if (this.light) {
      this.sidenavStyle = "sidenav-light";
    }
    this.subscription.add(
      this.navigationService.sideNavVisible$().subscribe((isVisible) => {
        this.sideNavHidden = !isVisible;
        this.changeDetectorRef.markForCheck();
      })
    );

    this.dashboard = "";
    this.reporting = "";
    this.users = "";
    this.notifications = "";
    this.tariff = "";
    this.alert = "";
    this.integration = "";
    this.jobbox = "";
    this.diagnostics = "";
    this.geolocation = "";

    let permissions = JSON.parse(localStorage.getItem("permissions") as string);
    this.notificationManagement = permissions.notificationManagement;
    this.tariffManagement = permissions.tariffManagement;
    this.reportManagement = permissions.reportManagement;
    this.userManagement = permissions.userManagement;
  }

  ngAfterViewInit() {
    this.activeTab = localStorage.getItem("active") as string;
    setTimeout(() => {
      if (this.activeTab == "dashboard") {
        this.dashboard = "active-item";
        this.LoadMeteringHeirachy();
      } else if (this.activeTab == "reporting") {
        this.reporting = "active-item";
        this.LoadMeteringHeirachy();
      } else if (this.activeTab == "users") {
        this.users = "active-item";
        this.LoadAccountHeirachy();
      } else if (this.activeTab == "notifications") {
        this.notifications = "active-item";
        this.LoadMeteringHeirachy();
      } else if (this.activeTab == "tariff") {
        this.tariff = "active-item";
        this.LoadMeteringHeirachy();
      } else if (this.activeTab == "alerts") {
        this.alert = "active-item";
        this.LoadMeteringHeirachy();
      } else if (this.activeTab == "integration") {
        this.integration = "active-item";
        this.LoadMeteringHeirachy();
      } else if (this.activeTab == "diagnostics") {
        this.diagnostics = "active-item";
        this.LoadMeteringHeirachy();
      } else if (this.activeTab == "geolocation") {
        this.geolocation = "active-item";
        this.LoadMeteringHeirachy();
      } else if (this.activeTab == "jobbox") {
        // this.jobbox = "active-item";
      } else {
        localStorage.setItem("active", "dashboard");
        this.dashboard = "active-item";
        this.LoadMeteringHeirachy();
      }
      this.changeDetectorRef.detectChanges();
    }, 1000);
    console.log("activeTab", this.activeTab);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeTab?.firstChange) {
      return;
    }
    this.reloadNav = "no";
    this.changeDetectorRef.detectChanges();

    this.reloadNav = "yes";

    if (localStorage.getItem("impersination") == "yes") {
      this.impersination = "yes";
    }
    this.changeDetectorRef.detectChanges();

    //set active tab
    this.dashboard = "";
    this.reporting = "";
    this.users = "";
    this.notifications = "";
    this.tariff = "";
    this.alert = "";
    this.integration = "";
    this.jobbox = "";
    this.diagnostics = "";
    this.geolocation = "";

    setTimeout(() => {
      if (this.activeTab == "dashboard") {
        this.dashboard = "active-item";
      } else if (this.activeTab == "reporting") {
        this.reporting = "active-item";
      } else if (this.activeTab == "users") {
        this.users = "active-item";
      } else if (this.activeTab == "notifications") {
        this.notifications = "active-item";
      } else if (this.activeTab == "tariff") {
        this.tariff = "active-item";
      } else if (this.activeTab == "alerts") {
        this.alert = "active-item";
      } else if (this.activeTab == "integration") {
        this.integration = "active-item";
      } else if (this.activeTab == "diagnostics") {
        this.diagnostics = "active-item";
      } else if (this.activeTab == "geolocation") {
        this.geolocation = "active-item";
      } else if (this.activeTab == "jobbox") {
        // this.jobbox = "active-item";
      } else {
        localStorage.setItem("active", "dashboard");
        this.dashboard = "active-item";
      }

      if (this.activeTab == "users") {
        if (localStorage.getItem("reload") == "yes") {
          this.LoadAccountHeirachy();
        } else {
          if (localStorage.getItem("account_hierarchy"))
            this.LoadAccountHeirachyJson();
          else {
            this.LoadAccountHeirachy();
          }
        }
      }
      // else {
      //     if(localStorage.getItem("reload") == "yes"){
      //         this.LoadMeteringHeirachy();
      //     }
      //     else{
      //         this.LoadAccountHeirachyJson();
      //     }
      // }
      else {
        this.LoadMeteringHeirachy();
      }

      this.changeDetectorRef.detectChanges();
    }, 2000);
    //}
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async LoadMeteringHeirachyJson() {
    let permissions = JSON.parse(localStorage.getItem("permissions") as string);
    this.deviceManagement = permissions.deviceManagement;
    this.meteringSectionManagement = permissions.meteringSectionManagement;
    this.meteringPointManagement = permissions.meteringPointManagement;

    let body: any = {
      payload: {
        userID: parseInt(localStorage.getItem("userID") as string),
      },
    };

    var data: any = JSON.parse(
      localStorage.getItem("metering_hierarchy") as string
    );

    // this.apiRequest.apiRequest('metering/metering_hierarchy/', 'metering_hierarchy.json', body)
    // .subscribe(data => {
    if (data.success == true) {
      this.sideNavSections[0].items = [];
      if (
        data.data.meteringFarms &&
        data.data.meteringFarms.length > 0 &&
        this.meteringSectionManagement != "no-access"
      ) {
        data.data.meteringFarms.forEach((element: any) => {
          // adding value to parent menu
          let _keyValue: keyvalue = {} as keyvalue;
          _keyValue.key = element.meteringFarmID;
          _keyValue.value = element.meteringFarmName;
          this.sideNavSections[0].items.push(_keyValue);
          let meteringSections: any = [];

          if (
            element.meteringSections &&
            element.meteringSections.length > 0 &&
            this.meteringSectionManagement != "no-access"
          ) {
            element.meteringSections.forEach(
              (ms_element: any, ms_index: any, ms_array: any[]) => {
                let meteringPoints: any = [];

                //Add for each metering section
                let meteringPoint = {
                  type: "modems",
                  icon: "",
                  userAccount: "yes",
                  imgSrc:
                    this.companyCheck == "landis"
                      ? "assets/landis-assets/images/modems.png"
                      : "assets/dashboard/menu/modem.png",
                  text: "Modems/Gateways",
                  key: ms_element.meteringSectionID,
                  level: "modems",
                };
                meteringPoints.push(meteringPoint);

                setTimeout(() => {
                  if (
                    ms_element.meteringPoints &&
                    ms_element.meteringPoints.length > 0 &&
                    this.meteringPointManagement != "no-access"
                  ) {
                    ms_element.meteringPoints.forEach(
                      (mp_element: any, mp_index: any, mp_array: any[]) => {
                        let deviceArr: any = [];

                        if (
                          mp_element.meters &&
                          mp_element.meters.length > 0 &&
                          this.deviceManagement != "no-access"
                        ) {
                          //only display for where there is meters
                          deviceArr.push({
                            type: "statement",
                            icon: "",
                            userAccount: "yes",
                            imgSrc: "assets/dashboard/menu/doc.png",
                            text: "View Statement",
                            key: mp_element.meteringPointID,
                            level: "statement",
                          });

                          mp_element.meters.forEach(
                            (
                              mt_element: any,
                              mt_index: any,
                              mt_array: any[]
                            ) => {
                              let ext = ".svg";
                              if (
                                mt_element.meterType != "water" &&
                                mt_element.meterType != "electricity" &&
                                mt_element.meterType != "gas"
                              )
                                ext = ".png";
                              deviceArr.push({
                                type: "",
                                text: mt_element.deviceName,
                                icon: "",
                                imgSrc:
                                  "assets/dashboard/menu/" +
                                  mt_element.meterType +
                                  ext,
                                level: "device",
                                key: mt_element.deviceID,
                                deviceType: mt_element.deviceType,
                                meterType: mt_element.meterType,
                                deviceVersion: mt_element.version,
                              });
                            }
                          );
                        }

                        let meteringPoint: SideNavItem = {
                          icon: "",
                          imgSrc:
                            this.companyCheck == "landis"
                              ? "assets/landis-assets/images/verticalbuilding.svg"
                              : "assets/dashboard/menu/vertical-building.svg",
                          text: mp_element.meteringPointName,
                          totalMeters: mp_element.totalMeters,
                          submenu: deviceArr,
                          key: mp_element.meteringPointID,
                          level: "meteringPoint",
                        };
                        meteringPoints.push(meteringPoint);
                        // this.changeDetectorRef.detectChanges();
                        if (mp_index === mp_array.length - 1) {
                          this.changeDetectorRef.markForCheck();
                        }
                        mp_index = mp_index + 1;
                      }
                    );
                  }
                }, 1000);

                let meteringSection: SideNavItem = {
                  icon: "",
                  imgSrc: "assets/landis-assets/images/meteringsectionicon.svg",
                  text: ms_element.meteringSectionName,
                  submenu: meteringPoints,
                  key: ms_element.meteringSectionID,
                  level: "meteringSection",
                };
                meteringSections.push(meteringSection);
                // this.changeDetectorRef.detectChanges();
                if (ms_index === ms_array.length - 1) {
                  this.changeDetectorRef.markForCheck();
                }
                ms_index = ms_index + 1;
              }
            );
          }

          // adding value 1st child
          let dataitem: SideNavItems = {
            [element.meteringFarmID]: {
              icon: "",
              imgSrc:
                this.companyCheck == "landis"
                  ? "assets/landis-assets/images/meteringfarm.svg"
                  : "assets/dashboard/menu/tree.svg",
              text: element.meteringFarmName,
              submenu: meteringSections,
              key: element.meteringFarmID,
              level: "meteringFarm",
            },
          };

          sideNavItemsList.AddData(dataitem);
          this.changeDetectorRef.detectChanges();
        });
      }

      if (
        data.data.meteringSections &&
        data.data.meteringSections.length > 0 &&
        this.meteringSectionManagement != "no-access"
      ) {
        data.data.meteringSections.forEach(
          (ms_element: any, ms_index: any, ms_array: any[]) => {
            // adding value to parent menu
            let _keyValue: keyvalue = {} as keyvalue;
            _keyValue.key = ms_element.meteringSectionID;
            _keyValue.value = ms_element.meteringSectionName;
            this.sideNavSections[0].items.push(_keyValue);

            let meteringPoints: any = [];

            //Add for each metering section
            let meteringPoint = {
              type: "modems",
              icon: "",
              userAccount: "yes",
              imgSrc:
                this.companyCheck == "landis"
                  ? "assets/landis-assets/images/modems.png"
                  : "assets/dashboard/menu/modem.png",
              text: "Modems/Gateways",
              key: ms_element.meteringSectionID,
              level: "modems",
            };
            meteringPoints.push(meteringPoint);

            setTimeout(() => {
              if (
                ms_element.meteringPoints &&
                ms_element.meteringPoints.length > 0 &&
                this.meteringPointManagement != "no-access"
              ) {
                ms_element.meteringPoints.forEach(
                  (mp_element: any, mp_index: any, mp_array: any[]) => {
                    let deviceArr: any = [];

                    if (
                      mp_element.meters &&
                      mp_element.meters.length > 0 &&
                      this.deviceManagement != "no-access"
                    ) {
                      //only display for where there is meters
                      deviceArr.push({
                        type: "statement",
                        icon: "",
                        userAccount: "yes",
                        imgSrc: "assets/dashboard/menu/doc.png",
                        text: "View Statement",
                        key: mp_element.meteringPointID,
                        level: "statement",
                      });

                      mp_element.meters.forEach(
                        (mt_element: any, mt_index: any, mt_array: any[]) => {
                          let ext = ".svg";
                          if (
                            mt_element.meterType != "water" &&
                            mt_element.meterType != "electricity" &&
                            mt_element.meterType != "gas"
                          )
                            ext = ".png";
                          deviceArr.push({
                            type: "",
                            text: mt_element.deviceName,
                            icon: "",
                            imgSrc:
                              "assets/dashboard/menu/" +
                              mt_element.meterType +
                              ext,
                            level: "device",
                            key: mt_element.deviceID,
                            deviceType: mt_element.deviceType,
                            meterType: mt_element.meterType,
                            deviceVersion: mt_element.version,
                          });
                        }
                      );
                    }

                    let meteringPoint: SideNavItem = {
                      icon: "",
                      imgSrc:
                        this.companyCheck == "landis"
                          ? "assets/landis-assets/images/verticalbuilding.svg"
                          : "assets/dashboard/menu/vertical-building.svg",
                      text: mp_element.meteringPointName,
                      totalMeters: mp_element.totalMeters,
                      submenu: deviceArr,
                      key: mp_element.meteringPointID,
                      level: "meteringPoint",
                    };
                    meteringPoints.push(meteringPoint);
                    // this.changeDetectorRef.detectChanges();
                    if (mp_index === mp_array.length - 1) {
                      this.changeDetectorRef.markForCheck();
                    }
                    mp_index = mp_index + 1;
                  }
                );
              }
            }, 1000);

            // adding value 1st child
            let dataitem: SideNavItems = {
              [ms_element.meteringSectionID]: {
                icon: "",
                imgSrc:
                  this.companyCheck == "landis"
                    ? "assets/landis-assets/images/meteringsectionicon.svg"
                    : "assets/dashboard/menu/building.svg",
                text: ms_element.meteringSectionName,
                submenu: meteringPoints,
                key: ms_element.meteringSectionID,
                level: "meteringSection",
              },
            };

            sideNavItemsList.AddData(dataitem);
            this.changeDetectorRef.detectChanges();
          }
        );
      }

      if (
        data.data.meteringPoints &&
        data.data.meteringPoints.length > 0 &&
        this.meteringPointManagement != "no-access"
      ) {
        data.data.meteringPoints.forEach(
          (mp_element: any, mp_index: any, mp_array: any[]) => {
            // adding value to parent menu
            let _keyValue: keyvalue = {} as keyvalue;
            _keyValue.key = mp_element.meteringPointID;
            (_keyValue.value = mp_element.meteringPointName),
              (_keyValue.totalMeters = mp_element.totalMeters),
              this.sideNavSections[0].items.push(_keyValue);

            let deviceArr: any = [];

            setTimeout(() => {
              if (
                mp_element.meters &&
                mp_element.meters.length > 0 &&
                this.deviceManagement != "no-access"
              ) {
                //only display for where there is meters
                deviceArr.push({
                  type: "statement",
                  icon: "",
                  userAccount: "yes",
                  imgSrc: "assets/dashboard/menu/doc.png",
                  text: "View Statement",
                  key: mp_element.meteringPointID,
                  level: "statement",
                });

                mp_element.meters.forEach(
                  (mt_element: any, mt_index: any, mt_array: any[]) => {
                    let ext = ".svg";
                    if (
                      mt_element.meterType != "water" &&
                      mt_element.meterType != "electricity" &&
                      mt_element.meterType != "gas"
                    )
                      ext = ".png";
                    deviceArr.push({
                      type: "",
                      text: mt_element.deviceName,
                      icon: "",
                      imgSrc:
                        "assets/dashboard/menu/" + mt_element.meterType + ext,
                      level: "device",
                      key: mt_element.deviceID,
                      deviceType: mt_element.deviceType,
                      meterType: mt_element.meterType,
                      deviceVersion: mt_element.version,
                    });
                  }
                );
              }
            }, 1000);

            // adding value 1st child
            let dataitem: SideNavItems = {
              [mp_element.meteringPointID]: {
                icon: "",
                imgSrc:
                  this.companyCheck == "landis"
                    ? "assets/landis-assets/images/meteringsectionicon.svg"
                    : "assets/dashboard/menu/building.svg",
                text: mp_element.meteringPointName,
                totalMeters: mp_element.totalMeters,
                submenu: deviceArr,
                key: mp_element.meteringPointID,
                level: "meteringPoint",
              },
            };

            sideNavItemsList.AddData(dataitem);
            this.changeDetectorRef.detectChanges();
          }
        );
      }
      localStorage.setItem("menuLoaded", "yes");
      this.reloadNav = "yes";
      this.changeDetectorRef.detectChanges();
      this.changeDetectorRef.markForCheck();
    }

    // },
    // (error: any) => {
    //     const defaultOptions = {
    //         autohide: true,
    //         delay: 10000,
    //     };
    //     localStorage.clear();
    //     console.log(error);
    //     let message = "";
    //     for (var i = 0; i < error.error.data.length; i++) {
    //         message += error.error.data[i] + "\n";
    //     }
    //     this.toastService.show("No Devices", error.error.data, { headerClasses: 'bg-danger text-light', ...defaultOptions });
    //     this.router.navigateByUrl("/auth/login");
    // });
  }

  async LoadMeteringHeirachy() {
    let permissions = JSON.parse(localStorage.getItem("permissions") as string);
    this.deviceManagement = permissions.deviceManagement;
    this.meteringSectionManagement = permissions.meteringSectionManagement;
    this.meteringPointManagement = permissions.meteringPointManagement;

    let body: any = {
      payload: {
        userID: parseInt(localStorage.getItem("userID") as string),
      },
    };

    this.apiRequest
      .apiRequest(
        "metering/metering_hierarchy/",
        "metering_hierarchy.json",
        body
      )
      .subscribe(
        (data) => {
          if (data.success == true) {
            this.sideNavSections[0].items = [];
            if (
              data.data.meteringFarms &&
              data.data.meteringFarms.length > 0 &&
              this.meteringSectionManagement != "no-access"
            ) {
              data.data.meteringFarms.forEach((element: any) => {
                // adding value to parent menu
                let _keyValue: keyvalue = {} as keyvalue;
                _keyValue.key = element.meteringFarmID;
                _keyValue.value = element.meteringFarmName;
                this.sideNavSections[0].items.push(_keyValue);
                let meteringSections: any = [];

                if (
                  element.meteringSections &&
                  element.meteringSections.length > 0 &&
                  this.meteringSectionManagement != "no-access"
                ) {
                  element.meteringSections.forEach(
                    (ms_element: any, ms_index: any, ms_array: any[]) => {
                      let meteringPoints: any = [];

                      //Add for each metering section
                      let meteringPoint = {
                        type: "modems",
                        icon: "",
                        userAccount: "yes",
                        imgSrc:
                          this.companyCheck == "landis"
                            ? "assets/landis-assets/images/modems.png"
                            : "assets/dashboard/menu/modem.png",
                        text: "Modems/Gateways",
                        key: ms_element.meteringSectionID,
                        level: "modems",
                      };
                      meteringPoints.push(meteringPoint);

                      setTimeout(() => {
                        if (
                          ms_element.meteringPoints &&
                          ms_element.meteringPoints.length > 0 &&
                          this.meteringPointManagement != "no-access"
                        ) {
                          ms_element.meteringPoints.forEach(
                            (
                              mp_element: any,
                              mp_index: any,
                              mp_array: any[]
                            ) => {
                              let deviceArr: any = [];

                              if (
                                mp_element.meters &&
                                mp_element.meters.length > 0 &&
                                this.deviceManagement != "no-access"
                              ) {
                                //only display for where there is meters
                                deviceArr.push({
                                  type: "statement",
                                  icon: "",
                                  userAccount: "yes",
                                  imgSrc: "assets/dashboard/menu/doc.png",
                                  text: "View Statement",
                                  key: mp_element.meteringPointID,
                                  level: "statement",
                                });

                                mp_element.meters.forEach(
                                  (
                                    mt_element: any,
                                    mt_index: any,
                                    mt_array: any[]
                                  ) => {
                                    let ext = ".svg";
                                    if (
                                      mt_element.meterType != "water" &&
                                      mt_element.meterType != "electricity" &&
                                      mt_element.meterType != "gas"
                                    )
                                      ext = ".png";
                                    deviceArr.push({
                                      type: "",
                                      text: mt_element.deviceName,
                                      icon: "",
                                      imgSrc:
                                        "assets/dashboard/menu/" +
                                        mt_element.meterType +
                                        ext,
                                      level: "device",
                                      key: mt_element.deviceID,
                                      deviceType: mt_element.deviceType,
                                      meterType: mt_element.meterType,
                                      deviceVersion: mt_element.version,
                                    });
                                  }
                                );
                              }

                              let meteringPoint: SideNavItem = {
                                icon: "",
                                imgSrc:
                                  this.companyCheck == "landis"
                                    ? "assets/landis-assets/images/verticalbuilding.svg"
                                    : "assets/dashboard/menu/vertical-building.svg",
                                text: mp_element.meteringPointName,
                                totalMeters: mp_element.totalMeters,
                                submenu: deviceArr,
                                key: mp_element.meteringPointID,
                                level: "meteringPoint",
                              };
                              meteringPoints.push(meteringPoint);
                              // this.changeDetectorRef.detectChanges();
                              if (mp_index === mp_array.length - 1) {
                                this.changeDetectorRef.markForCheck();
                              }
                              mp_index = mp_index + 1;
                            }
                          );
                        }
                      }, 1000);

                      let meteringSection: SideNavItem = {
                        icon: "",
                        imgSrc:
                          this.companyCheck == "landis"
                            ? "assets/landis-assets/images/meteringsectionicon.svg"
                            : "assets/dashboard/menu/building.svg",
                        text: ms_element.meteringSectionName,
                        submenu: meteringPoints,
                        key: ms_element.meteringSectionID,
                        level: "meteringSection",
                      };
                      meteringSections.push(meteringSection);
                      // this.changeDetectorRef.detectChanges();
                      if (ms_index === ms_array.length - 1) {
                        this.changeDetectorRef.markForCheck();
                      }
                      ms_index = ms_index + 1;
                    }
                  );
                }

                // adding value 1st child
                let dataitem: SideNavItems = {
                  [element.meteringFarmID]: {
                    icon: "",
                    imgSrc:
                      this.companyCheck == "landis"
                        ? "assets/landis-assets/images/meteringfarm.svg"
                        : "assets/dashboard/menu/tree.svg",
                    text: element.meteringFarmName,
                    submenu: meteringSections,
                    key: element.meteringFarmID,
                    level: "meteringFarm",
                  },
                };

                sideNavItemsList.AddData(dataitem);
                this.changeDetectorRef.detectChanges();
              });
            }

            if (
              data.data.meteringSections &&
              data.data.meteringSections.length > 0 &&
              this.meteringSectionManagement != "no-access"
            ) {
              data.data.meteringSections.forEach(
                (ms_element: any, ms_index: any, ms_array: any[]) => {
                  // adding value to parent menu
                  let _keyValue: keyvalue = {} as keyvalue;
                  _keyValue.key = ms_element.meteringSectionID;
                  _keyValue.value = ms_element.meteringSectionName;
                  this.sideNavSections[0].items.push(_keyValue);

                  let meteringPoints: any = [];

                  //Add for each metering section
                  let meteringPoint = {
                    type: "modems",
                    icon: "",
                    userAccount: "yes",
                    imgSrc:
                      this.companyCheck == "landis"
                        ? "assets/landis-assets/images/modems.png"
                        : "assets/dashboard/menu/modem.png",
                    text: "Modems/Gateways",
                    key: ms_element.meteringSectionID,
                    level: "modems",
                  };
                  meteringPoints.push(meteringPoint);

                  setTimeout(() => {
                    if (
                      ms_element.meteringPoints &&
                      ms_element.meteringPoints.length > 0 &&
                      this.meteringPointManagement != "no-access"
                    ) {
                      ms_element.meteringPoints.forEach(
                        (mp_element: any, mp_index: any, mp_array: any[]) => {
                          let deviceArr: any = [];

                          if (
                            mp_element.meters &&
                            mp_element.meters.length > 0 &&
                            this.deviceManagement != "no-access"
                          ) {
                            //only display for where there is meters
                            deviceArr.push({
                              type: "statement",
                              icon: "",
                              userAccount: "yes",
                              imgSrc: "assets/dashboard/menu/doc.png",
                              text: "View Statement",
                              key: mp_element.meteringPointID,
                              level: "statement",
                            });

                            mp_element.meters.forEach(
                              (
                                mt_element: any,
                                mt_index: any,
                                mt_array: any[]
                              ) => {
                                let ext = ".svg";
                                if (
                                  mt_element.meterType != "water" &&
                                  mt_element.meterType != "electricity" &&
                                  mt_element.meterType != "gas"
                                )
                                  ext = ".png";
                                deviceArr.push({
                                  type: "",
                                  text: mt_element.deviceName,
                                  icon: "",
                                  imgSrc:
                                    "assets/dashboard/menu/" +
                                    mt_element.meterType +
                                    ext,
                                  level: "device",
                                  key: mt_element.deviceID,
                                  deviceType: mt_element.deviceType,
                                  meterType: mt_element.meterType,
                                  deviceVersion: mt_element.version,
                                });
                              }
                            );
                          }

                          let meteringPoint: SideNavItem = {
                            icon: "",
                            imgSrc:
                              this.companyCheck == "landis"
                                ? "assets/landis-assets/images/verticalbuilding.svg"
                                : "assets/dashboard/menu/vertical-building.svg",
                            text: mp_element.meteringPointName,
                            totalMeters: mp_element.totalMeters,
                            submenu: deviceArr,
                            key: mp_element.meteringPointID,
                            level: "meteringPoint",
                          };
                          meteringPoints.push(meteringPoint);
                          // this.changeDetectorRef.detectChanges();
                          if (mp_index === mp_array.length - 1) {
                            this.changeDetectorRef.markForCheck();
                          }
                          mp_index = mp_index + 1;
                        }
                      );
                    }
                  }, 1000);

                  // adding value 1st child
                  let dataitem: SideNavItems = {
                    [ms_element.meteringSectionID]: {
                      icon: "",
                      imgSrc:
                        this.companyCheck == "landis"
                          ? "assets/landis-assets/images/meteringsectionicon.svg"
                          : "assets/dashboard/menu/building.svg",
                      text: ms_element.meteringSectionName,
                      submenu: meteringPoints,
                      key: ms_element.meteringSectionID,
                      level: "meteringSection",
                    },
                  };

                  sideNavItemsList.AddData(dataitem);
                  this.changeDetectorRef.detectChanges();
                }
              );
            }

            if (
              data.data.meteringPoints &&
              data.data.meteringPoints.length > 0 &&
              this.meteringPointManagement != "no-access"
            ) {
              data.data.meteringPoints.forEach(
                (mp_element: any, mp_index: any, mp_array: any[]) => {
                  // adding value to parent menu
                  let _keyValue: keyvalue = {} as keyvalue;
                  _keyValue.key = mp_element.meteringPointID;
                  (_keyValue.value = mp_element.meteringPointName),
                    (_keyValue.totalMeters = mp_element.totalMeters),
                    this.sideNavSections[0].items.push(_keyValue);

                  let deviceArr: any = [];

                  setTimeout(() => {
                    if (
                      mp_element.meters &&
                      mp_element.meters.length > 0 &&
                      this.deviceManagement != "no-access"
                    ) {
                      //only display for where there is meters
                      deviceArr.push({
                        type: "statement",
                        icon: "",
                        userAccount: "yes",
                        imgSrc: "assets/dashboard/menu/doc.png",
                        text: "View Statement",
                        key: mp_element.meteringPointID,
                        level: "statement",
                      });

                      mp_element.meters.forEach(
                        (mt_element: any, mt_index: any, mt_array: any[]) => {
                          let ext = ".svg";
                          if (
                            mt_element.meterType != "water" &&
                            mt_element.meterType != "electricity" &&
                            mt_element.meterType != "gas"
                          )
                            ext = ".png";
                          deviceArr.push({
                            type: "",
                            text: mt_element.deviceName,
                            icon: "",
                            imgSrc:
                              "assets/dashboard/menu/" +
                              mt_element.meterType +
                              ext,
                            level: "device",
                            key: mt_element.deviceID,
                            deviceType: mt_element.deviceType,
                            meterType: mt_element.meterType,
                            deviceVersion: mt_element.version,
                          });
                        }
                      );
                    }
                  }, 1000);

                  // adding value 1st child
                  let dataitem: SideNavItems = {
                    [mp_element.meteringPointID]: {
                      icon: "",
                      imgSrc:
                        this.companyCheck == "landis"
                          ? "assets/landis-assets/images/meteringsectionicon.svg"
                          : "assets/dashboard/menu/building.svg",
                      text: mp_element.meteringPointName,
                      totalMeters: mp_element.totalMeters,
                      submenu: deviceArr,
                      key: mp_element.meteringPointID,
                      level: "meteringPoint",
                    },
                  };

                  sideNavItemsList.AddData(dataitem);
                  this.changeDetectorRef.detectChanges();
                }
              );
            }
            localStorage.setItem("menuLoaded", "yes");
            this.reloadNav = "yes";
            this.changeDetectorRef.detectChanges();
            this.changeDetectorRef.markForCheck();
            localStorage.setItem("reload", "no");
          }
        },
        (error: any) => {
          const defaultOptions = {
            autohide: true,
            delay: 10000,
          };
          localStorage.clear();
          console.log(error);
          let message = "";
          for (var i = 0; i < error.error.data.length; i++) {
            message += error.error.data[i] + "\n";
          }
          this.toastService.show("No Devices", error.error.data, {
            headerClasses: "bg-danger text-light",
            ...defaultOptions,
          });
          this.router.navigateByUrl("/auth/login");
        }
      );
  }

  async LoadAccountHeirachyJson() {
    let permissions = JSON.parse(localStorage.getItem("permissions") as string);
    this.deviceManagement = permissions.deviceManagement;
    this.meteringSectionManagement = permissions.meteringSectionManagement;
    this.meteringPointManagement = permissions.meteringPointManagement;

    let body: any = {
      payload: {
        userID: parseInt(localStorage.getItem("userID") as string),
      },
    };

    var data: any = JSON.parse(
      localStorage.getItem("account_hierarchy") as string
    );

    // this.apiRequest.apiRequest('metering/account_hierarchy/', 'account_hierarchy.json', body)
    // .subscribe(data => {
    if (data.success) {
      this.sideNavSections[0].items = [];
      if (
        data.data.meteringFarms &&
        data.data.meteringFarms.length > 0 &&
        this.meteringSectionManagement != "no-access"
      ) {
        let meteringFarms: any = [];

        data.data.meteringFarms.forEach((element: any) => {
          // adding value to parent menu
          let _keyValue: keyvalue = {} as keyvalue;
          _keyValue.key = element.meteringFarmID;
          _keyValue.value = element.meteringFarmName;
          this.sideNavSections[0].items.push(_keyValue);
          let meteringSections: any = [];

          let meteringSectionAllUsers: SideNavItem = {
            type: "account",
            icon: "",
            userAccount: "yes",
            pmLevel: "all",
            imgSrc: this.companyCheck == "landis"
            ? "assets/landis-assets/images/landisUserAll.png"
            : "assets/dashboard/menu/userAll.png",
            text: "All Accounts",
            textLevel: element.meteringFarmName,
            submenu: [],
            key: element.meteringFarmID,
            level: "meteringFarm",
          };
          meteringSections.push(meteringSectionAllUsers);

          let meteringSectionAdminUsers: SideNavItem = {
            type: "account",
            icon: "",
            userAccount: "yes",
            pmLevel: "1",
            imgSrc: this.companyCheck == "landis"
            ? "assets/landis-assets/images/landisUserAdmin.png"
            : "assets/dashboard/menu/userAdmin.png",
            text: "Admins Only",
            textLevel: element.meteringFarmName,
            submenu: [],
            key: element.meteringFarmID,
            level: "meteringFarm",
          };
          meteringSections.push(meteringSectionAdminUsers);

          if (
            element.meteringSections &&
            element.meteringSections.length > 0 &&
            this.meteringSectionManagement != "no-access"
          ) {
            element.meteringSections.forEach(
              (ms_element: any, ms_index: any, ms_array: any[]) => {
                let meteringPoints: any = [];

                setTimeout(() => {
                  if (
                    ms_element.meteringPoints &&
                    ms_element.meteringPoints.length > 0 &&
                    this.meteringPointManagement != "no-access"
                  ) {
                    ms_element.meteringPoints.forEach(
                      (mp_element: any, mp_index: any, mp_array: any[]) => {
                        let deviceArr: any = [];

                        if (
                          mp_element.meters &&
                          mp_element.meters.length > 0 &&
                          this.deviceManagement != "no-access"
                        ) {
                          mp_element.meters.forEach(
                            (
                              mt_element: any,
                              mt_index: any,
                              mt_array: any[]
                            ) => {
                              let ext = ".svg";
                              if (
                                mt_element.meterType != "water" &&
                                mt_element.meterType != "electricity" &&
                                mt_element.meterType != "gas"
                              )
                                ext = ".png";
                              deviceArr.push({
                                type: "account",
                                text: mt_element.deviceName,
                                icon: "",
                                imgSrc:
                                  "assets/dashboard/menu/" +
                                  mt_element.meterType +
                                  ext,
                                level: "device",
                                key: mt_element.deviceID,
                                deviceType: mt_element.deviceType,
                                meterType: mt_element.meterType,
                                deviceVersion: mt_element.version,
                              });
                            }
                          );
                        }

                        let meteringPoint: SideNavItem = {
                          type: "account",
                          icon: "",
                          imgSrc:
                            this.companyCheck == "landis"
                              ? "assets/landis-assets/images/verticalbuilding.svg"
                              : "assets/dashboard/menu/vertical-building.svg",
                          text: mp_element.meteringPointName,
                          submenu: deviceArr,
                          key: mp_element.meteringPointID,
                          level: "meteringPoint",
                        };
                        meteringPoints.push(meteringPoint);
                        // this.changeDetectorRef.detectChanges();
                        if (mp_index === mp_array.length - 1) {
                          this.changeDetectorRef.markForCheck();
                        }
                        mp_index = mp_index + 1;
                      }
                    );
                  }
                }, 1000);

                let meteringSectionAllUsers: SideNavItem = {
                  type: "account",
                  icon: "",
                  userAccount: "yes",
                  pmLevel: "all",
                  imgSrc: this.companyCheck == "landis"
                  ? "assets/landis-assets/images/landisUserAll.png"
                  : "assets/dashboard/menu/userAll.png",
                  text: "All Accounts",
                  textLevel: ms_element.meteringSectionName,
                  submenu: [],
                  key: ms_element.meteringSectionID,
                  level: "meteringSection",
                };
                meteringPoints.push(meteringSectionAllUsers);

                let meteringSectionAdminUsers: SideNavItem = {
                  type: "account",
                  icon: "",
                  userAccount: "yes",
                  pmLevel: "2",
                  imgSrc: this.companyCheck == "landis"
                  ? "assets/landis-assets/images/landisUserAdmin.png"
                  : "assets/dashboard/menu/userAdmin.png",
                  text: "Managers Only",
                  textLevel: ms_element.meteringSectionName,
                  submenu: [],
                  key: ms_element.meteringSectionID,
                  level: "meteringSection",
                };
                meteringPoints.push(meteringSectionAdminUsers);

                let meteringSection: SideNavItem = {
                  type: "account",
                  icon: "",
                  imgSrc:
                    this.companyCheck == "landis"
                      ? "assets/landis-assets/images/meteringsectionicon.svg"
                      : "assets/dashboard/menu/building.svg",
                  text: ms_element.meteringSectionName,
                  submenu: meteringPoints,
                  key: ms_element.meteringSectionID,
                  level: "meteringSection",
                };
                meteringSections.push(meteringSection);

                // this.changeDetectorRef.detectChanges();
                if (ms_index === ms_array.length - 1) {
                  this.changeDetectorRef.markForCheck();
                }
                ms_index = ms_index + 1;
              }
            );
          }

          // adding value 1st child
          let dataitem: SideNavItems = {
            [element.meteringFarmID]: {
              type: "account",
              icon: "",
              imgSrc:
                this.companyCheck == "landis"
                  ? "assets/landis-assets/images/meteringfarm.svg"
                  : "assets/dashboard/menu/tree.svg",
              text: element.meteringFarmName,
              submenu: meteringSections,
              key: element.meteringFarmID,
              level: "meteringFarm",
            },
          };

          sideNavItemsList.AddData(dataitem);
          this.changeDetectorRef.detectChanges();
        });
      }

      if (
        data.data.meteringSections &&
        data.data.meteringSections.length > 0 &&
        this.meteringSectionManagement != "no-access"
      ) {
        data.data.meteringSections.forEach(
          (ms_element: any, ms_index: any, ms_array: any[]) => {
            // adding value to parent menu
            let _keyValue: keyvalue = {} as keyvalue;
            _keyValue.key = ms_element.meteringSectionID;
            _keyValue.value = ms_element.meteringSectionName;
            this.sideNavSections[0].items.push(_keyValue);

            let meteringPoints: any = [];

            setTimeout(() => {
              if (
                ms_element.meteringPoints &&
                ms_element.meteringPoints.length > 0 &&
                this.meteringPointManagement != "no-access"
              ) {
                ms_element.meteringPoints.forEach(
                  (mp_element: any, mp_index: any, mp_array: any[]) => {
                    let deviceArr: any = [];

                    if (
                      mp_element.meters &&
                      mp_element.meters.length > 0 &&
                      this.deviceManagement != "no-access"
                    ) {
                      mp_element.meters.forEach(
                        (mt_element: any, mt_index: any, mt_array: any[]) => {
                          // deviceArr.push({
                          //     type: 'account',
                          //     text: mt_element.deviceName,
                          //     icon: '',
                          //     imgSrc: 'assets/dashboard/menu/' + mt_element.meterType + '.svg',
                          //     level: "device",
                          //     key: mt_element.deviceID,
                          //     deviceType: mt_element.deviceType,
                          //     meterType: mt_element.meterType,
                          // })
                        }
                      );
                    }

                    let meteringPoint: SideNavItem = {
                      type: "account",
                      icon: "",
                      imgSrc:
                        this.companyCheck == "landis"
                          ? "assets/landis-assets/images/verticalbuilding.svg"
                          : "assets/dashboard/menu/vertical-building.svg",
                      text: mp_element.meteringPointName,
                      submenu: deviceArr,
                      key: mp_element.meteringPointID,
                      level: "meteringPoint",
                    };
                    meteringPoints.push(meteringPoint);
                    // this.changeDetectorRef.detectChanges();
                    if (mp_index === mp_array.length - 1) {
                      this.changeDetectorRef.markForCheck();
                    }
                    mp_index = mp_index + 1;
                  }
                );
              }
            }, 1000);

            let meteringSectionAllUsers: SideNavItem = {
              type: "account",
              icon: "",
              userAccount: "yes",
              pmLevel: "all",
              imgSrc: this.companyCheck == "landis"
              ? "assets/landis-assets/images/landisUserAll.png"
              : "assets/dashboard/menu/userAll.png",
              text: "All Accounts",
              textLevel: ms_element.meteringSectionName,
              submenu: [],
              key: ms_element.meteringSectionID,
              level: "meteringSection",
            };
            meteringPoints.push(meteringSectionAllUsers);

            let meteringSectionAdminUsers: SideNavItem = {
              type: "account",
              icon: "",
              userAccount: "yes",
              pmLevel: "2",
              imgSrc: this.companyCheck == "landis"
              ? "assets/landis-assets/images/landisUserAdmin.png"
              : "assets/dashboard/menu/userAdmin.png",
              text: "Managers Only",
              textLevel: ms_element.meteringSectionName,
              submenu: [],
              key: ms_element.meteringSectionID,
              level: "meteringSection",
            };
            meteringPoints.push(meteringSectionAdminUsers);

            // adding value 1st child
            let dataitem: SideNavItems = {
              [ms_element.meteringSectionID]: {
                type: "account",
                icon: "",
                imgSrc:
                  this.companyCheck == "landis"
                    ? "assets/landis-assets/images/meteringsectionicon.svg"
                    : "assets/dashboard/menu/building.svg",
                text: ms_element.meteringSectionName,
                submenu: meteringPoints,
                key: ms_element.meteringSectionID,
                level: "meteringSection",
              },
            };

            sideNavItemsList.AddData(dataitem);
            this.changeDetectorRef.detectChanges();
          }
        );
      }

      if (
        data.data.meteringPoints &&
        data.data.meteringPoints.length > 0 &&
        this.meteringPointManagement != "no-access"
      ) {
        data.data.meteringPoints.forEach(
          (mp_element: any, mp_index: any, mp_array: any[]) => {
            // adding value to parent menu
            let _keyValue: keyvalue = {} as keyvalue;
            _keyValue.key = mp_element.meteringPointID;
            _keyValue.value = mp_element.meteringPointName;
            this.sideNavSections[0].items.push(_keyValue);

            let deviceArr: any = [];

            setTimeout(() => {
              if (
                mp_element.meters &&
                mp_element.meters.length > 0 &&
                this.deviceManagement != "no-access"
              ) {
                mp_element.meters.forEach(
                  (mt_element: any, mt_index: any, mt_array: any[]) => {
                    let ext = ".svg";
                    if (
                      mt_element.meterType != "water" &&
                      mt_element.meterType != "electricity" &&
                      mt_element.meterType != "gas"
                    )
                      ext = ".png";
                    deviceArr.push({
                      type: "account",
                      text: mt_element.deviceName,
                      icon: "",
                      imgSrc:
                        "assets/dashboard/menu/" + mt_element.meterType + ext,
                      level: "device",
                      key: mt_element.deviceID,
                      deviceType: mt_element.deviceType,
                      meterType: mt_element.meterType,
                      deviceVersion: mt_element.version,
                    });
                  }
                );
              }
            }, 1000);

            // adding value 1st child
            let dataitem: SideNavItems = {
              [mp_element.meteringPointID]: {
                type: "account",
                icon: "",
                imgSrc:
                  this.companyCheck == "landis"
                    ? "assets/landis-assets/images/meteringsectionicon.svg"
                    : "assets/dashboard/menu/building.svg",
                text: mp_element.meteringPointName,
                submenu: deviceArr,
                key: mp_element.meteringPointID,
                level: "meteringPoint",
              },
            };

            sideNavItemsList.AddData(dataitem);
            this.changeDetectorRef.detectChanges();
          }
        );
      }
      localStorage.setItem("menuLoaded", "yes");
      this.reloadNav = "yes";
      this.changeDetectorRef.detectChanges();
      this.changeDetectorRef.markForCheck();
    }

    // },
    // (error: any) => {
    //     const defaultOptions = {
    //         autohide: true,
    //         delay: 10000,
    //     };
    //     localStorage.clear();
    //     console.log(error);
    //     let message = "";
    //     for (var i = 0; i < error.error.data.length; i++) {
    //         message += error.error.data[i] + "\n";
    //     }
    //     this.toastService.show("No Devices", error.error.data, { headerClasses: 'bg-danger text-light', ...defaultOptions });
    //     this.router.navigateByUrl("/auth/login");
    // });
  }

  async LoadAccountHeirachy() {
    let permissions = JSON.parse(localStorage.getItem("permissions") as string);
    this.deviceManagement = permissions.deviceManagement;
    this.meteringSectionManagement = permissions.meteringSectionManagement;
    this.meteringPointManagement = permissions.meteringPointManagement;

    let body: any = {
      payload: {
        userID: parseInt(localStorage.getItem("userID") as string),
      },
    };

    // var data: any = JSON.parse(localStorage.getItem('account_hierarchy') as string);

    this.apiRequest
      .apiRequest("metering/account_hierarchy/", "account_hierarchy.json", body)
      .subscribe(
        (data) => {
          if (data.success == true) {
            console.log("Save json account_hierarchy");
            localStorage.setItem("account_hierarchy", JSON.stringify(data));
            this.sideNavSections[0].items = [];
            if (
              data.data.meteringFarms &&
              data.data.meteringFarms.length > 0 &&
              this.meteringSectionManagement != "no-access"
            ) {
              let meteringFarms: any = [];

              data.data.meteringFarms.forEach((element: any) => {
                // adding value to parent menu
                let _keyValue: keyvalue = {} as keyvalue;
                _keyValue.key = element.meteringFarmID;
                _keyValue.value = element.meteringFarmName;
                this.sideNavSections[0].items.push(_keyValue);
                let meteringSections: any = [];

                let meteringSectionAllUsers: SideNavItem = {
                  type: "account",
                  icon: "",
                  userAccount: "yes",
                  pmLevel: "all",
                  imgSrc: this.companyCheck == "landis"
                  ? "assets/landis-assets/images/landisUserAll.png"
                  : "assets/dashboard/menu/userAll.png",
                  text: "All Accounts",
                  textLevel: element.meteringFarmName,
                  submenu: [],
                  key: element.meteringFarmID,
                  level: "meteringFarm",
                };
                meteringSections.push(meteringSectionAllUsers);

                let meteringSectionAdminUsers: SideNavItem = {
                  type: "account",
                  icon: "",
                  userAccount: "yes",
                  pmLevel: "1",
                  imgSrc: this.companyCheck == "landis"
                  ? "assets/landis-assets/images/landisUserAdmin.png"
                  : "assets/dashboard/menu/userAdmin.png",
                  text: "Admins Only",
                  textLevel: element.meteringFarmName,
                  submenu: [],
                  key: element.meteringFarmID,
                  level: "meteringFarm",
                };
                meteringSections.push(meteringSectionAdminUsers);

                if (
                  element.meteringSections &&
                  element.meteringSections.length > 0 &&
                  this.meteringSectionManagement != "no-access"
                ) {
                  element.meteringSections.forEach(
                    (ms_element: any, ms_index: any, ms_array: any[]) => {
                      let meteringPoints: any = [];

                      setTimeout(() => {
                        if (
                          ms_element.meteringPoints &&
                          ms_element.meteringPoints.length > 0 &&
                          this.meteringPointManagement != "no-access"
                        ) {
                          ms_element.meteringPoints.forEach(
                            (
                              mp_element: any,
                              mp_index: any,
                              mp_array: any[]
                            ) => {
                              let deviceArr: any = [];

                              if (
                                mp_element.meters &&
                                mp_element.meters.length > 0 &&
                                this.deviceManagement != "no-access"
                              ) {
                                mp_element.meters.forEach(
                                  (
                                    mt_element: any,
                                    mt_index: any,
                                    mt_array: any[]
                                  ) => {
                                    let ext = ".svg";
                                    if (
                                      mt_element.meterType != "water" &&
                                      mt_element.meterType != "electricity" &&
                                      mt_element.meterType != "gas"
                                    )
                                      ext = ".png";
                                    deviceArr.push({
                                      type: "account",
                                      text: mt_element.deviceName,
                                      icon: "",
                                      imgSrc:
                                        "assets/dashboard/menu/" +
                                        mt_element.meterType +
                                        ext,
                                      level: "device",
                                      key: mt_element.deviceID,
                                      deviceType: mt_element.deviceType,
                                      meterType: mt_element.meterType,
                                      deviceVersion: mt_element.version,
                                    });
                                  }
                                );
                              }

                              let meteringPoint: SideNavItem = {
                                type: "account",
                                icon: "",
                                imgSrc:
                                  this.companyCheck == "landis"
                                    ? "assets/landis-assets/images/verticalbuilding.svg"
                                    : "assets/dashboard/menu/vertical-building.svg",
                                text: mp_element.meteringPointName,
                                submenu: deviceArr,
                                key: mp_element.meteringPointID,
                                level: "meteringPoint",
                              };
                              meteringPoints.push(meteringPoint);
                              // this.changeDetectorRef.detectChanges();
                              if (mp_index === mp_array.length - 1) {
                                this.changeDetectorRef.markForCheck();
                              }
                              mp_index = mp_index + 1;
                            }
                          );
                        }
                      }, 1000);

                      let meteringSectionAllUsers: SideNavItem = {
                        type: "account",
                        icon: "",
                        userAccount: "yes",
                        pmLevel: "all",
                        imgSrc: this.companyCheck == "landis"
                        ? "assets/landis-assets/images/landisUserAll.png"
                        : "assets/dashboard/menu/userAll.png",
                        text: "All Accounts",
                        textLevel: ms_element.meteringSectionName,
                        submenu: [],
                        key: ms_element.meteringSectionID,
                        level: "meteringSection",
                      };
                      meteringPoints.push(meteringSectionAllUsers);

                      let meteringSectionAdminUsers: SideNavItem = {
                        type: "account",
                        icon: "",
                        userAccount: "yes",
                        pmLevel: "2",
                        imgSrc: this.companyCheck == "landis"
                        ? "assets/landis-assets/images/landisUserAdmin.png"
                        : "assets/dashboard/menu/userAdmin.png",
                        text: "Managers Only",
                        textLevel: ms_element.meteringSectionName,
                        submenu: [],
                        key: ms_element.meteringSectionID,
                        level: "meteringSection",
                      };
                      meteringPoints.push(meteringSectionAdminUsers);

                      let meteringSection: SideNavItem = {
                        type: "account",
                        icon: "",
                        imgSrc:
                          this.companyCheck == "landis"
                            ? "assets/landis-assets/images/meteringsectionicon.svg"
                            : "assets/dashboard/menu/building.svg",
                        text: ms_element.meteringSectionName,
                        submenu: meteringPoints,
                        key: ms_element.meteringSectionID,
                        level: "meteringSection",
                      };
                      meteringSections.push(meteringSection);

                      // this.changeDetectorRef.detectChanges();
                      if (ms_index === ms_array.length - 1) {
                        this.changeDetectorRef.markForCheck();
                      }
                      ms_index = ms_index + 1;
                    }
                  );
                }

                // adding value 1st child
                let dataitem: SideNavItems = {
                  [element.meteringFarmID]: {
                    type: "account",
                    icon: "",
                    imgSrc:
                      this.companyCheck == "landis"
                        ? "assets/landis-assets/images/meteringfarm.svg"
                        : "assets/dashboard/menu/tree.svg",
                    text: element.meteringFarmName,
                    submenu: meteringSections,
                    key: element.meteringFarmID,
                    level: "meteringFarm",
                  },
                };

                sideNavItemsList.AddData(dataitem);
                this.changeDetectorRef.detectChanges();
              });
            }

            if (
              data.data.meteringSections &&
              data.data.meteringSections.length > 0 &&
              this.meteringSectionManagement != "no-access"
            ) {
              data.data.meteringSections.forEach(
                (ms_element: any, ms_index: any, ms_array: any[]) => {
                  // adding value to parent menu
                  let _keyValue: keyvalue = {} as keyvalue;
                  _keyValue.key = ms_element.meteringSectionID;
                  _keyValue.value = ms_element.meteringSectionName;
                  this.sideNavSections[0].items.push(_keyValue);

                  let meteringPoints: any = [];

                  setTimeout(() => {
                    if (
                      ms_element.meteringPoints &&
                      ms_element.meteringPoints.length > 0 &&
                      this.meteringPointManagement != "no-access"
                    ) {
                      ms_element.meteringPoints.forEach(
                        (mp_element: any, mp_index: any, mp_array: any[]) => {
                          let deviceArr: any = [];

                          if (
                            mp_element.meters &&
                            mp_element.meters.length > 0 &&
                            this.deviceManagement != "no-access"
                          ) {
                            mp_element.meters.forEach(
                              (
                                mt_element: any,
                                mt_index: any,
                                mt_array: any[]
                              ) => {
                                // deviceArr.push({
                                //     type: 'account',
                                //     text: mt_element.deviceName,
                                //     icon: '',
                                //     imgSrc: 'assets/dashboard/menu/' + mt_element.meterType + '.svg',
                                //     level: "device",
                                //     key: mt_element.deviceID,
                                //     deviceType: mt_element.deviceType,
                                //     meterType: mt_element.meterType,
                                // })
                              }
                            );
                          }

                          let meteringPoint: SideNavItem = {
                            type: "account",
                            icon: "",
                            imgSrc:
                              this.companyCheck == "landis"
                                ? "assets/landis-assets/images/verticalbuilding.svg"
                                : "assets/dashboard/menu/vertical-building.svg",
                            text: mp_element.meteringPointName,
                            submenu: deviceArr,
                            key: mp_element.meteringPointID,
                            level: "meteringPoint",
                          };
                          meteringPoints.push(meteringPoint);
                          // this.changeDetectorRef.detectChanges();
                          if (mp_index === mp_array.length - 1) {
                            this.changeDetectorRef.markForCheck();
                          }
                          mp_index = mp_index + 1;
                        }
                      );
                    }
                  }, 1000);

                  let meteringSectionAllUsers: SideNavItem = {
                    type: "account",
                    icon: "",
                    userAccount: "yes",
                    pmLevel: "all",
                    imgSrc: this.companyCheck == "landis"
                    ? "assets/landis-assets/images/landisUserAll.png"
                    : "assets/dashboard/menu/userAll.png",
                    text: "All Accounts",
                    textLevel: ms_element.meteringSectionName,
                    submenu: [],
                    key: ms_element.meteringSectionID,
                    level: "meteringSection",
                  };
                  meteringPoints.push(meteringSectionAllUsers);

                  let meteringSectionAdminUsers: SideNavItem = {
                    type: "account",
                    icon: "",
                    userAccount: "yes",
                    pmLevel: "2",
                    imgSrc: this.companyCheck == "landis"
                    ? "assets/landis-assets/images/landisUserAdmin.png"
                    : "assets/dashboard/menu/userAdmin.png",
                    text: "Managers Only",
                    textLevel: ms_element.meteringSectionName,
                    submenu: [],
                    key: ms_element.meteringSectionID,
                    level: "meteringSection",
                  };
                  meteringPoints.push(meteringSectionAdminUsers);

                  // adding value 1st child
                  let dataitem: SideNavItems = {
                    [ms_element.meteringSectionID]: {
                      type: "account",
                      icon: "",
                      imgSrc:
                        this.companyCheck == "landis"
                          ? "assets/landis-assets/images/meteringsectionicon.svg"
                          : "assets/dashboard/menu/building.svg",
                      text: ms_element.meteringSectionName,
                      submenu: meteringPoints,
                      key: ms_element.meteringSectionID,
                      level: "meteringSection",
                    },
                  };

                  sideNavItemsList.AddData(dataitem);
                  this.changeDetectorRef.detectChanges();
                }
              );
            }

            if (
              data.data.meteringPoints &&
              data.data.meteringPoints.length > 0 &&
              this.meteringPointManagement != "no-access"
            ) {
              data.data.meteringPoints.forEach(
                (mp_element: any, mp_index: any, mp_array: any[]) => {
                  // adding value to parent menu
                  let _keyValue: keyvalue = {} as keyvalue;
                  _keyValue.key = mp_element.meteringPointID;
                  _keyValue.value = mp_element.meteringPointName;
                  this.sideNavSections[0].items.push(_keyValue);

                  let deviceArr: any = [];

                  setTimeout(() => {
                    if (
                      mp_element.meters &&
                      mp_element.meters.length > 0 &&
                      this.deviceManagement != "no-access"
                    ) {
                      mp_element.meters.forEach(
                        (mt_element: any, mt_index: any, mt_array: any[]) => {
                          let ext = ".svg";
                          if (
                            mt_element.meterType != "water" &&
                            mt_element.meterType != "electricity" &&
                            mt_element.meterType != "gas"
                          )
                            ext = ".png";
                          deviceArr.push({
                            type: "account",
                            text: mt_element.deviceName,
                            icon: "",
                            imgSrc:
                              "assets/dashboard/menu/" +
                              mt_element.meterType +
                              ext,
                            level: "device",
                            key: mt_element.deviceID,
                            deviceType: mt_element.deviceType,
                            meterType: mt_element.meterType,
                            deviceVersion: mt_element.version,
                          });
                        }
                      );
                    }
                  }, 1000);

                  // adding value 1st child
                  let dataitem: SideNavItems = {
                    [mp_element.meteringPointID]: {
                      type: "account",
                      icon: "",
                      imgSrc:
                        this.companyCheck == "landis"
                          ? "assets/landis-assets/images/meteringsectionicon.svg"
                          : "assets/dashboard/menu/building.svg",
                      text: mp_element.meteringPointName,
                      submenu: deviceArr,
                      key: mp_element.meteringPointID,
                      level: "meteringPoint",
                    },
                  };

                  sideNavItemsList.AddData(dataitem);
                  this.changeDetectorRef.detectChanges();
                }
              );
            }
            localStorage.setItem("menuLoaded", "yes");
            this.reloadNav = "yes";
            this.changeDetectorRef.detectChanges();
            this.changeDetectorRef.markForCheck();
            localStorage.setItem("reload", "no");
          }
        },
        (error: any) => {
          const defaultOptions = {
            autohide: true,
            delay: 10000,
          };
          localStorage.clear();
          console.log(error);
          let message = "";
          for (var i = 0; i < error.error.data.length; i++) {
            message += error.error.data[i] + "\n";
          }
          this.toastService.show("No Devices", error.error.data, {
            headerClasses: "bg-danger text-light",
            ...defaultOptions,
          });
          this.router.navigateByUrl("/auth/login");
        }
      );
  }

  closeSideNavIfOpen() {
    const BOOTSTRAP_LG_WIDTH = 992;
    if (this.utilityService.window.innerWidth >= 992) {
      return;
    }
    // After the lg breakpoint, hidden is actually visible.
    // So the toggleSideNav below only will fire if the screen is < 992px
    // and the sideNav is open.
    if (this.sideNavHidden) {
      this.navigationService.toggleSideNav(true);
    }
  }

  mainDashNav(option: string) {
    this.dashboard = "";
    this.reporting = "";
    this.users = "";
    this.notifications = "";
    this.tariff = "";
    this.alert = "";
    this.diagnostics = "";
    this.geolocation = "";

    localStorage.setItem("menuIsReset", "no");
    localStorage.setItem("reload", "no");

    if (localStorage.getItem("active") == "users" && option != "users") {
      //localStorage.setItem("reload", "yes");
    }
    if (localStorage.getItem("active") != "users" && option == "users") {
      //localStorage.setItem("reload", "yes");
    }
    localStorage.setItem("active", "");

    if (option == "dashboard") {
      localStorage.setItem("activeLevel", "meteringSection");

      localStorage.setItem("active", "dashboard");
      this.dashboard = "active-item";
      this.navigationService.router.navigateByUrl("/dashboard");
    }
    if (option == "reporting") {
      localStorage.setItem("active", "reporting");
      this.reporting = "active-item";
      this.navigationService.router.navigateByUrl("/dashboard/reporting");
    }
    if (option == "users") {
      if (localStorage.getItem("meteringFarmID")) {
        localStorage.setItem("activeLevel", "meteringFarm");
      } else if (localStorage.getItem("meteringSectionID")) {
        localStorage.setItem("activeLevel", "meteringSection");
      } else if (localStorage.getItem("meteringPointID")) {
        localStorage.setItem("activeLevel", "meteringPoint");
      }
      localStorage.setItem("active", "users");

      this.users = "active-item";
      this.navigationService.router.navigateByUrl("/dashboard/users");
    }
    if (option == "notifications") {
      localStorage.setItem("active", "notifications");
      this.users = "active-item";
      if (parseInt(this.pmLevel) < 3) {
        this.navigationService.router.navigateByUrl(
          "/dashboard/notifications/profileSettings"
        );
      } else {
        this.navigationService.router.navigateByUrl(
          "/dashboard/notifications/profileSettings"
        );
      }
    }
    if (option == "tariff") {
      localStorage.setItem("active", "tariff");
      this.tariff = "active-item";
      this.navigationService.router.navigateByUrl("/dashboard/tariff/myTariff");
    }
    if (option == "alerts") {
      localStorage.setItem("active", "alerts");
      this.alert = "active-item";
      this.navigationService.router.navigateByUrl(
        "/dashboard/notifications/alerts"
      );
    }
    if (option == "integration") {
      localStorage.setItem("active", "integration");
      this.integration = "active-item";
      this.navigationService.router.navigateByUrl(
        "/dashboard/integration/integrationList"
      );
    }
    if (option == "diagnostics") {
      localStorage.setItem("active", "diagnostics");
      this.diagnostics = "active-item";
      this.navigationService.router.navigateByUrl(
        "/dashboard/diagnostics/diagnostics"
      );
    }
    if (option == "geolocation") {
      localStorage.setItem("active", "geolocation");
      this.geolocation = "active-item";
      this.navigationService.router.navigateByUrl(
        "/dashboard/diagnostics/geolocation"
      );
    }
    if (option == "jobbox") {
      // this.jobbox = "active-item";
      if (localStorage.getItem("userID") && localStorage.getItem("email")) {
        //open jobbox platform
        let environment: any = localStorage.getItem("environment");
        let userID: any = localStorage.getItem("userID");
        let userEmail: any = localStorage.getItem("email");
        let encodedUserID = Buffer.from(userID, "binary").toString("base64");
        let encodedUserEmail = Buffer.from(userEmail, "binary").toString(
          "base64"
        );

        if (
          localStorage.getItem("environment") &&
          (localStorage.getItem("environment") == "production" ||
            localStorage.getItem("environment") == "prod")
        ) {
          window.open(
            "https://job-box.smartviewtechnology.co.za/autologin/" +
              encodedUserID +
              "/" +
              encodedUserEmail,
            "_blank"
          );
        } else {
          window.open(
            "https://" +
              environment +
              "-dot-smart-view-job-box.ew.r.appspot.com/autologin/" +
              encodedUserID +
              "/" +
              encodedUserEmail,
            "_blank"
          );
        }
      }
    }
  }
}
