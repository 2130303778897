import { Injectable } from "@angular/core";
import { UtilityService } from "@modules/app-common/services";
import { Subject } from "rxjs";

const _expand$ = new Subject<string[]>();

export interface ExpandedTable {
  [index: string]: boolean;
}

const EXPANDED_TABLE_CACHE_NAME = "sbpro-expanded-table";

@Injectable()
export class SideNavService {
  [x: string]: any;
  expandedTable: ExpandedTable = {};
  private backgroundTaskCanvasSubject = new Subject<any>();
  backgroundTaskCanvas$ = this.backgroundTaskCanvasSubject.asObservable();

  constructor(private utilityService: UtilityService) {
    const cachedExpandedTable =
      this.utilityService.getStoredObject<ExpandedTable>(
        EXPANDED_TABLE_CACHE_NAME
      );
    if (cachedExpandedTable) {
      this.expandedTable = cachedExpandedTable;
    }
  }

  // Method to trigger the background task canvas
  openBackgroundTaskCanvas(option: any, response: any, title: any) {
    this.backgroundTaskCanvasSubject.next({ option, title, response });
  }

  closeBackgroundTaskCanvas() {
    console.log('closeBackgroundTaskCanvas is being called'); 
    this.backgroundTaskCanvasSubject.next({ option: "close" });
  }

  get expand$() {
    return _expand$;
  }

  isExpanded(hash: string): boolean {
    if (this.expandedTable[hash]) {
      return true;
    }
    return false;
  }

  setExpanded(hash: string, expanded: boolean) {
    this.expandedTable[hash] = expanded;
  }

  setDefault(hierarchyExtension: string[]) {
    if (
      Object.keys(this.expandedTable).length === 0 &&
      this.expandedTable.constructor === Object
    ) {
      this.saveCache(hierarchyExtension, "/dashboard");
    }
  }

  saveCache(hierarchyExtension: string[], link: string) {
    if (!link.match(/^\/dashboard/)) {
      return;
    }
    this.expandedTable = {};
    hierarchyExtension.forEach((id) => (this.expandedTable[id] = true));
    this.utilityService.storeObject(
      EXPANDED_TABLE_CACHE_NAME,
      this.expandedTable
    );
  }
}
