import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { SBRouteData, SideNavItem } from '@modules/navigation/models';
import { NavigationService, SideNavService } from '@modules/navigation/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@modules/auth/services';
import { ApiRequestService } from '@common/services/api-request.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { ToastService } from '@common/services/toast.service';

@Component({
    selector: 'sbpro-top-nav-user',
    templateUrl: './top-nav-user.component.html',
    styleUrls: ['top-nav-user.component.scss'],
})
export class TopNavUserComponent implements OnInit {
    @Input() placement = 'bottom-end';
    dropdownClasses: string[] = [];
    username = "";
    email = "";
    @Input() impersination!: string;

    meteringFarms: any = [];
    meteringSections: any = [];
    meteringPointsArr: any = [];
    meteringPoints: any = [];
    deviceList: any = [];
    createAccountBtn = 'yes';

    deviceManagement = "";
    meteringSectionManagement = "";
    meteringPointManagement = "";
    companyCheck = localStorage.getItem('company');

    constructor(
        public userService: UserService,
        private apiRequest: ApiRequestService,
        private ngxService: NgxUiLoaderService,
        private modalService: NgbModal,
        public navigationService: NavigationService,
        private router: Router,
        private toastService: ToastService, 
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.username = localStorage.getItem("name") as string;
        this.email = localStorage.getItem("email") as string;
        
        if(localStorage.getItem('impersination') == "yes"){
            this.impersination = "yes";
        }
        this.changeDetectorRef.detectChanges();
    }

    logout(){
        /* if(localStorage.getItem("socialUser") && localStorage.getItem("socialUser") != "") {
            localStorage.clear();
            localStorage.setItem("socialUser", "socialUser");
        }else{
            localStorage.clear();
        }
        window.location.assign("/auth/login"); */

        const bulkIds = localStorage.getItem("bulkIds");
        const selectedMeter = localStorage.getItem("selectedMeter");
        const meterSectionIdSync = localStorage.getItem("meterSectionIdSync");
        const companyCheck = localStorage.getItem('company');

        localStorage.clear();
        if(companyCheck == "landis"){
            localStorage.setItem("company", companyCheck);
        }
        if (bulkIds) {
            localStorage.setItem("selectedMeter", selectedMeter ?? '');
            localStorage.setItem("bulkIds", bulkIds);
            localStorage.setItem("selectedBulkIds", 'yes');
        }

        if(meterSectionIdSync){
            localStorage.setItem("meterSectionIdSync", meterSectionIdSync);
        }
        window.location.assign("/auth/login");
    }


    exitImpersination() {
        this.ngxService.start();
            setTimeout(() => {
                this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
            }, 2000);
    
        let body = {
          payload: {
            userID: parseInt(localStorage.getItem('mainUserID') as string),
            mainUserID: parseInt(localStorage.getItem('mainUserID') as string),
          },
        };

        // localStorage.setItem("access_token", localStorage.getItem('main_token') as string);
        // localStorage.setItem("mainUserID", localStorage.getItem('userID') as string);
        localStorage.setItem("impersination", 'no');
        localStorage.setItem("meteringPointStatement", "no");

        this.impersination = "no";
        this.changeDetectorRef.detectChanges();
        
        this.apiRequest.apiRequest("auth/impersonate_account/", "back_to_main.json", body)
          .subscribe((res) => {
            if (res.data.userID) {
                localStorage.setItem("userID", res.data.userID);
                localStorage.setItem("access_token", res.data.access.access_token);
                localStorage.setItem("username", res.data.name);
                localStorage.setItem("email", res.data.email);
                localStorage.setItem("companyName", res.data.companyName);
                localStorage.setItem("pmLevel", res.data.pmLevel);
                localStorage.setItem("permissions", JSON.stringify(res.data.access.permissions));
                localStorage.setItem("impersination", 'no');
                localStorage.setItem("reload", 'yes');
                localStorage.removeItem('deviceID');
                setTimeout(() => {
                    // this.router.navigateByUrl(url);
                    // window.location.assign("/dashboard")
                    this.getMeteringHierachy();
                    this.getAccountHierachyReload();
                }, 500)

            }
            this.changeDetectorRef.detectChanges();
          },
          (error: any) => {
              const defaultOptions = {
                  autohide: true,
                  delay: 5000,
              };
              
              console.log(error);
              let message = "";
              for(var i=0; i < error.error.data.length; i++){
                  message += error.error.data[i]+"\n";
              }
              this.toastService.show("Login Error", "There was an error login you in.", { headerClasses: 'bg-danger text-light', ...defaultOptions });
    
          });
    
      }


    getAccountHierachyReload() {
        let body = {
            payload: {
                userID: parseInt(localStorage.getItem("userID") as string),
            },
        };
        localStorage.setItem("active", "dashboard");

        let jsonF = "account_hierarchy.json";
        if (localStorage.getItem("pmLevel") == "1") {
            jsonF = "account_hierarchy.json";
        }
        if (localStorage.getItem("pmLevel") == "2") {
            jsonF = "account_hierarchy_2.json";
        }
        else if (localStorage.getItem("pmLevel") == "3" || localStorage.getItem("pmLevel") == "4") {
            jsonF = "account_hierarchy_3.json";
        }

        const defaultOptions = {
            autohide: true,
            delay: 10000,
        };

        this.apiRequest.apiRequest("metering/account_hierarchy/", jsonF, body)
            .subscribe((res) => {
                console.log(res.message);
                if (res.success) {
                    console.log('Save json account_hierarchy');
                    localStorage.setItem('account_hierarchy', JSON.stringify((res)));

                }
                
            },
                (error: any) => {
                    const defaultOptions = {
                        autohide: true,
                        delay: 10000,
                    };
                    console.log(error);
                    let message = "";
                    for (var i = 0; i < error.error.data.length; i++) {
                        message += error.error.data[i] + "\n";
                    }
                    this.toastService.show("Smart-View Login", "You account setup is not complete. \nContact your Administrator for more information.", { headerClasses: 'bg-danger text-light', ...defaultOptions })
                });
    }


      getMeteringHierachy() {
        let body = {
            payload: {
                userID: parseInt(localStorage.getItem("userID") as string),
            },
        };
        localStorage.setItem("active", "dashboard");

        let permissions = JSON.parse(localStorage.getItem("permissions") as string);
        this.deviceManagement = permissions.deviceManagement;
        this.meteringSectionManagement = permissions.meteringSectionManagement;
        this.meteringPointManagement = permissions.meteringPointManagement;

        let jsonF = "metering_hierarchy.json";
        if (localStorage.getItem("pmLevel") == "1") {
            jsonF = "metering_hierarchy.json";
        }
        if (localStorage.getItem("pmLevel") == "2") {
            jsonF = "metering_hierarchy_2.json";
        }
        else if (localStorage.getItem("pmLevel") == "3" || localStorage.getItem("pmLevel") == "4") {
            jsonF = "metering_hierarchy_3.json";
        }

        const defaultOptions = {
            autohide: true,
            delay: 10000,
        };

        //var res: any = JSON.parse(localStorage.getItem('metering_hierarchy') as string);


        this.apiRequest.apiRequest("metering/metering_hierarchy/", jsonF, body)
            .subscribe((res) => {
                console.log(res.message);
                if (res.success) {
                    console.log('Save json metering_hierarchy');
                    localStorage.setItem('metering_hierarchy', JSON.stringify((res)));
                    if (res.data.meteringFarms) {
                        this.meteringFarms = res.data.meteringFarms;
                        localStorage.setItem("meteringFarmID", res.data.meteringFarms[0].meteringFarmID);
                        localStorage.setItem("meteringFarm", res.data.meteringFarms[0].meteringFarmName);
                        localStorage.setItem("activeLevel", "meteringFarm");
                        
                        for (var i = 0; i < res.data.meteringFarms.length; i++) {
                            //check for metering sections
                            if (res.data.meteringFarms[i].meteringSections.length > 0) {
                                this.meteringSections.push(res.data.meteringFarms[i].meteringSections);
                                localStorage.setItem("meteringSectionID", res.data.meteringFarms[i].meteringSections[0].meteringSectionID);
                                localStorage.setItem("meteringSection", res.data.meteringFarms[i].meteringSections[0].meteringSectionName);

                                for (var j = 0; j < res.data.meteringFarms[i].meteringSections.length; j++) {
                                    localStorage.setItem("hasMeteringSection", "yes");

                                    if (res.data.meteringFarms[i].meteringSections[j].meteringPoints.length > 0) {
                                        this.meteringPointsArr[
                                            res.data.meteringFarms[i].meteringSections[j].meteringSectionID
                                        ] = res.data.meteringFarms[i].meteringSections[j].meteringPoints;

                                        localStorage.setItem("meteringPointID", res.data.meteringFarms[i].meteringSections[j].meteringPoints[0].meteringPointID);
                                        localStorage.setItem("meteringPoint", res.data.meteringFarms[i].meteringSections[j].meteringPoints[0].meteringPointName);

                                        for (var x = 0; x < res.data.meteringFarms[i].meteringSections[j].meteringPoints.length; x++) {
                                            if (res.data.meteringFarms[i].meteringSections[j].meteringPoints[x].meters.length > 0) {
                                            this.deviceList.push(res.data.meteringFarms[i].meteringSections[j].meteringPoints[x].meters)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    else if (res.data.meteringSections) {
                        localStorage.setItem("hasMeteringSection", "yes");
                        this.meteringSections = res.data.meteringSections;
                        //demo test
                        localStorage.setItem("meteringSectionID", res.data.meteringSections[0].meteringSectionID);
                        localStorage.setItem("meteringSection", res.data.meteringSections[0].meteringSectionName);

                        localStorage.setItem("activeLevel", "meteringSection");

                        for (var j = 0; j < res.data.meteringSections.length; j++) {
                            if (res.data.meteringSections[j].meteringPoints.length > 0) {
                                this.meteringPointsArr[
                                    res.data.meteringSections[j].meteringSectionID
                                ] = res.data.meteringSections[j].meteringPoints;

                                localStorage.setItem("meteringPointID", res.data.meteringSections[j].meteringPoints[0].meteringPointID);
                                localStorage.setItem("meteringPoint", res.data.meteringSections[j].meteringPoints[0].meteringPointName);

                                for (var x = 0; x < res.data.meteringSections[j].meteringPoints.length; x++) {
                                    if (res.data.meteringSections[j].meteringPoints[x].meters.length > 0) {
                                    this.deviceList.push(res.data.meteringSections[j].meteringPoints[x].meters)
                                    }
                                }
                            }
                        }
                    }
                    else if (res.data.meteringPoints) {
                        this.meteringPoints = res.data.meteringPoints;
                        this.meteringPointsArr[0] = res.data.meteringPoints;
                        localStorage.setItem("meteringPointID", res.data.meteringPoints[0].meteringPointID);
                        localStorage.setItem("meteringPoint", res.data.meteringPoints[0].meteringPointName);

                        localStorage.setItem("activeLevel", "meteringPoint");

                        for (var x = 0; x < res.data.meteringPoints.length; x++) {
                            if (res.data.meteringPoints[x].meters.length > 0) {
                                this.deviceList.push(res.data.meteringPoints[x].meters)
                            }
                        }
                    }


                    if (this.meteringSections.length > 0) {
                        localStorage.setItem("hasMeteringSection", "yes");
                    }
                    if (this.meteringPointsArr.length > 0) {
                        localStorage.setItem("hasMeteringPoint", "yes");
                    }

                    //this.router.navigate(['/dashboard']);
                    window.location.assign('/dashboard')

                    setTimeout(() =>{
                        this.router.navigate(['/dashboard/users']);
                    },1000)

                }
                else {
                    localStorage.clear();
                    this.router.navigate(['/auth/login']);
                }
                this.changeDetectorRef.detectChanges();
            },
                (error: any) => {
                    const defaultOptions = {
                        autohide: true,
                        delay: 10000,
                    };
                    console.log(error);
                    let message = "";
                    for (var i = 0; i < error.error.data.length; i++) {
                        message += error.error.data[i] + "\n";
                    }
                    localStorage.clear();
                    this.toastService.show("Smart-View Login", "You account setup is not complete. \nContact your Administrator for more information.", { headerClasses: 'bg-danger text-light', ...defaultOptions })
                });
    }

    
}
