// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//testing: https://testing-dot-smart-view-utilities-18c15.ey.r.appspot.com/
//prod: https://utilities.smartviewtechnology.co.za/mobileApp/

export const environment = {
  production: true,
  apiUrl: "https://smartviewiotapi-gq5tbkf56q-ey.a.run.app/",
  companyCheck: "default",
  firebaseConfig: {
    apiKey: "AIzaSyArxYN_xDh2Wqt9cUdKoy8OOAk5HkZEnrA",
    authDomain: "smart-view-utilities-18c15.firebaseapp.com",
    projectId: "smart-view-utilities-18c15",
    storageBucket: "smart-view-utilities-18c15.appspot.com",
    messagingSenderId: "974164964060",
    appId: "1:974164964060:ios:585ea5bd96eb70df4eba19",
  },
  GoogleLoginProvider: "409120509615-5rfe5lh1jts075ds9jd6cujreqigmqk2.apps.googleusercontent.com",
  FacebookLoginProvider: "412827963454919"
};

export const environmentStage = {
  production: false,
  apiUrl: "https://smartviewiotapistage-gq5tbkf56q-ey.a.run.app/",
  companyCheck: "default",
  firebaseConfig: {
    apiKey: "AIzaSyArxYN_xDh2Wqt9cUdKoy8OOAk5HkZEnrA",
    authDomain: "smart-view-utilities-18c15.firebaseapp.com",
    projectId: "smart-view-utilities-18c15",
    storageBucket: "smart-view-utilities-18c15.appspot.com",
    messagingSenderId: "974164964060",
    appId: "1:974164964060:ios:585ea5bd96eb70df4eba19",
  },
  GoogleLoginProvider: "409120509615-5rfe5lh1jts075ds9jd6cujreqigmqk2.apps.googleusercontent.com",
  FacebookLoginProvider: "412827963454919"
};

export const environmentTesting = {
  production: false,
  apiUrl: "https://smartviewiotapitesting-eco2stiima-ey.a.run.app/",
  companyCheck: "default",
  firebaseConfig: {
    apiKey: "AIzaSyArxYN_xDh2Wqt9cUdKoy8OOAk5HkZEnrA",
    authDomain: "smart-view-utilities-18c15.firebaseapp.com",
    projectId: "smart-view-utilities-18c15",
    storageBucket: "smart-view-utilities-18c15.appspot.com",
    messagingSenderId: "974164964060",
    appId: "1:974164964060:ios:585ea5bd96eb70df4eba19",
  },
  GoogleLoginProvider: "409120509615-5rfe5lh1jts075ds9jd6cujreqigmqk2.apps.googleusercontent.com",
  FacebookLoginProvider: "412827963454919"
};

export const environmentDev = {
  production: false,
  apiUrl: "https://smartviewiotapidevelopment-eco2stiima-ey.a.run.app/",
  companyCheck: "default",
  firebaseConfig: {
    apiKey: "AIzaSyArxYN_xDh2Wqt9cUdKoy8OOAk5HkZEnrA",
    authDomain: "smart-view-utilities-18c15.firebaseapp.com",
    projectId: "smart-view-utilities-18c15",
    storageBucket: "smart-view-utilities-18c15.appspot.com",
    messagingSenderId: "974164964060",
    appId: "1:974164964060:ios:585ea5bd96eb70df4eba19",
  },
  GoogleLoginProvider: "409120509615-5rfe5lh1jts075ds9jd6cujreqigmqk2.apps.googleusercontent.com",
  FacebookLoginProvider: "412827963454919"
};

export const environmentDemo = {
  production: false,
  apiUrl: "assets/utilitiesdemo/",
  companyCheck: "default",
  firebaseConfig: {
    apiKey: "AIzaSyArxYN_xDh2Wqt9cUdKoy8OOAk5HkZEnrA",
    authDomain: "smart-view-utilities-18c15.firebaseapp.com",
    projectId: "smart-view-utilities-18c15",
    storageBucket: "smart-view-utilities-18c15.appspot.com",
    messagingSenderId: "974164964060",
    appId: "1:974164964060:ios:585ea5bd96eb70df4eba19",
  },
  GoogleLoginProvider: "409120509615-5rfe5lh1jts075ds9jd6cujreqigmqk2.apps.googleusercontent.com",
  FacebookLoginProvider: "412827963454919"
};

export const environmentLocal = {
  production: false,
  apiUrl: "http://127.0.0.1:8000/",
  companyCheck: "default",
  firebaseConfig: {
    apiKey: "AIzaSyArxYN_xDh2Wqt9cUdKoy8OOAk5HkZEnrA",
    authDomain: "smart-view-utilities-18c15.firebaseapp.com",
    projectId: "smart-view-utilities-18c15",
    storageBucket: "smart-view-utilities-18c15.appspot.com",
    messagingSenderId: "974164964060",
    appId: "1:974164964060:ios:585ea5bd96eb70df4eba19",
  },
  GoogleLoginProvider: "409120509615-5rfe5lh1jts075ds9jd6cujreqigmqk2.apps.googleusercontent.com",
  FacebookLoginProvider: "412827963454919"
};

export const environmentMigration = {
  production: false,
  apiUrl: "https://smartviewiotapitesting-eco2stiima-ey.a.run.app/",
  companyCheck: "default",
  firebaseConfig: {
    apiKey: "AIzaSyArxYN_xDh2Wqt9cUdKoy8OOAk5HkZEnrA",
    authDomain: "smart-view-utilities-18c15.firebaseapp.com",
    projectId: "smart-view-utilities-18c15",
    storageBucket: "smart-view-utilities-18c15.appspot.com",
    messagingSenderId: "974164964060",
    appId: "1:974164964060:ios:585ea5bd96eb70df4eba19",
  },
  GoogleLoginProvider: "409120509615-5rfe5lh1jts075ds9jd6cujreqigmqk2.apps.googleusercontent.com",
  FacebookLoginProvider: "412827963454919"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
