import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { ToastService } from "@common/services/toast.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "sbpro-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["toast.component.scss"],
})
export class ToastComponent implements OnInit {
  constructor(
    public toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  @Input() header!: string | TemplateRef<unknown>;
  @Input() body!: string | TemplateRef<unknown>;
  @Input() autohide = false;
  @Input() delay!: number;
  @Input() classes!: string;
  @Input() headerClasses!: string;
  @Input() bodyClasses!: string;
  @Input() toastID!: string;
  @Input() icon?: string;
  @Input() showButton?: boolean = false;
  @Input() note!: string;
  @Input() closeCondition?: boolean = false;

  headerIsTemplate!: boolean;
  bodyIsTemplate!: boolean;

  timeoutID!: number;

  toastContext = this;
  checkMeterSynced = "";

  ngOnInit() {
    this.headerIsTemplate = this.header instanceof TemplateRef;
    this.bodyIsTemplate = this.body instanceof TemplateRef;
    this.checkMeterSynced = localStorage.getItem("meterSynced") ?? "yes";
    if (this.autohide) {
      this.timeoutID = window.setTimeout(() => {
        this.toastService.remove(this.toastID);
      }, this.delay || 5000);
    }
  }

  dismissToast() {
    this.toastService.remove(this.toastID);
  }

  handleButtonClick(id: any) {
    this.toastService.remove(id);
    localStorage.setItem("reportType", "billingReport");
    const syncMeterType = localStorage.getItem("meterType");
    console.log(syncMeterType);
    localStorage.setItem("syncMeterType", syncMeterType!);
    const syncMeterDate = localStorage.getItem("dateFilter");
    localStorage.setItem("syncMeterDate", syncMeterDate!);
    localStorage.setItem("syncMeter", "no");
    /*  document.getElementById("btnApply")!.click(); */
    if (this.closeCondition == true) {
      window.location.assign("/dashboard/reporting");
    } else {
      this.router.navigate(["/dashboard/reporting"], {
        queryParams: { viewSync: "true" },
      });
    }
  }
}
